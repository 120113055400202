// Annotate each feature flag with owner. Remove here when code references to feature flags are removed and experiments complete
export const FEATURE_FLAGS = {
  NPS_SURVEY_DROPOFF: 'nps-survey-dropoff', // owner: @alexkim205
  CHATPG_ELIGIBILITY_POLLING: 'chatpg-eligibility-polling', // owner: @aalmazan
  NOTIFICATIONS: 'notifications', // owner: @alexkim205 and @aalmazan
};

// Some experiments that have major changes that affect the flow or auth are run at the url level.
export const SEARCH_PARAM_FEATURE_FLAGS = {
  EMAIL_LATER: 'email-later', // owner: @alexkim205
};
