export enum UserQuery {
  Me = 'me',
  SignIn = 'signIn',
  SignOut = 'signOut',
  RestorePassword = 'restorePassword',
  ResetPassword = 'resetPassword',
  RequestOtp = 'requestOtp',
  VerifyOtp = 'verifyOtp',
  UpdatePerson = 'updatePerson',
  UpdatePatient = 'updatePatient',
  UpdatePatientEthnicities = 'updatePatientEthnicities',
  UserValidation = 'userValidation',
}

export const queries: Record<UserQuery, string> = {
  [UserQuery.Me]: `query me {
      me {
        __typename
        id
        username
        firstName
        lastName
        email
        patients {
          __typename
          id
          firstName
          dateOfBirth
          biologicalSex
          parentsRelated
          hematologicMalignancy
          boneMarrowStemCellTransplant
          physicians {
            __typename
            id
            address1
            address2
            zipCode
            state
            city
            specialties {
              id
              name
            }
            npi
            phoneNumber
            faxNumber
            isPrimary
          }
        }
        person {
          id
          phone
          referenceId
          firstName
          lastName
          formResponses {
            edges {
              node {
                pk
                patient {
                  firstName
                  lastName
                }
                submittedDate
                completedTestingQuestions
                referenceId
                customerRelationshipToPatient
                status
                result
                deleted
                orderReferenceId
                conversation {
                  id
                  formResponse {
                    id
                    referenceId
                    result
                    status
                    deleted
                  }
                  created
                  relationshipToPatient
                }
                hasOpportunities
              }
            }
          }
        }
      }
    }`,
  [UserQuery.UserValidation]: `query userValidation($filter: UserFilterInput!) {
    userValidation(filter: $filter) {
      totalCount
    }
  }
  `,
  [UserQuery.SignIn]: `mutation signIn($password: String!, $username: String!) {
      signIn(password: $password, username: $username) {
        __typename
        user {
          __typename
          id
          isSuperuser
          username
          firstName
          lastName
          email
          isStaff
          token
        }
        redirectUrl
      }
    }`,
  [UserQuery.SignOut]: `mutation signOut {
      signOut {
        __typename
        id
      }
    }`,
  [UserQuery.RestorePassword]: `mutation restorePassword($email: String!, $returnUrl: String) {
      restorePassword(email: $email, returnUrl: $returnUrl) {
        __typename
        ok
        error
      }
    }`,
  [UserQuery.ResetPassword]: `mutation resetPassword($email: String!, $password: String!, $token: String!) {
      resetPassword(email: $email, password: $password, token: $token) {
        __typename
        ok
        error
      }
    }`,
  [UserQuery.RequestOtp]: `mutation requestOtp($email: String!) {
      requestOtp(email: $email) {
        __typename
        ok
        error
      }
    }`,
  [UserQuery.VerifyOtp]: `mutation verifyOtp($email: String!, $otp: String!) {
      verifyOtp(email: $email, otp: $otp) {
        __typename
        ok
        error
      }
    }`,
  [UserQuery.UpdatePerson]: `mutation updatePerson($input: UpdatePersonInput) {
      updatePerson(input: $input) {
        __typename
        id
        person {
          __typename
          id
          latitude
          longitude
          referenceId
          ipAddress
          phone
          firstName
          lastName
          timezone
          city
          region
          country
          email
        }
      }
    }`,
  [UserQuery.UpdatePatient]: `mutation updatePatient($input: UpdatePatientInput) {
    updatePatient(input: $input) {
      __typename
      id
      patient {
        __typename
        id
        firstName
        lastName
        dateOfBirth
        ageOfOnset
        biologicalSex
        parentsRelated
        hematologicMalignancy
        boneMarrowStemCellTransplant
        ethnicities {
          __typename
          id
          ethnicity
        }
      }
    }
  }`,
  [UserQuery.UpdatePatientEthnicities]: `mutation updatePatientEthnicities($input: UpdatePatientEthnicitiesInput) {
    updatePatientEthnicities(input: $input) {
      __typename
      id
      patient {
        __typename
        id
        firstName
        lastName
        dateOfBirth
        biologicalSex
        parentsRelated
        hematologicMalignancy
        boneMarrowStemCellTransplant
        ethnicities {
          __typename
          id
          ethnicity
        }
      }
    }
  }`,
};
