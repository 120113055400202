import {
  toParams,
  determineBaseUrl,
  determineBaseSSUrl,
} from '@/helpers/utils';
import { gql } from 'graphql-request';
import { userRoutes } from '@/lib/api/user';
import { conversationRoutes } from '@/lib/api/conversation';
import { genericRoutes } from '@/lib/api/generic';
import { formResponseRoutes } from '@/lib/api/formResponse';
import { physicianRoutes } from '@/lib/api/physician';
import { searchDiagnosesRoutes } from '@/lib/api/searchDiagnoses';
import { opportunitiesRoutes } from '@/lib/api/opportunities';
import { orderRoutes } from '@/lib/api/order';
import { eligibilityRoutes } from '@/lib/api/eligibility';
import { dashboardRoutes } from './dashboard';
import { AxiosError } from 'axios';
import { geneRoutes } from './gene';

export function getErrorMessage(
  e: AxiosError | Error | unknown,
  defaultMessage = 'Please try again'
) {
  let errorMessage = defaultMessage;
  if (e instanceof AxiosError) {
    if (e?.response?.data) {
      // The error message will come in a `string[]` type, but it
      // should be fine to use on the `setIntakeManualErrors` method below.
      errorMessage = e.response.data;
    }
  } else if (e instanceof Error) {
    errorMessage = e.message || defaultMessage;
  }
  return errorMessage;
}

export interface ApiMethodOptions {
  signal?: AbortSignal;
  snakify?: boolean;
}

export class ApiRequest {
  private pathComponents: string[];
  private queryString: string | undefined;

  constructor() {
    this.pathComponents = [];
  }

  // URL assembly

  public assembleEndpointUrl(): string {
    let url = this.pathComponents.join('/') + '/';
    if (this.queryString) {
      if (!this.queryString.startsWith('?')) {
        url += '?';
      }
      url += this.queryString;
    }
    return url;
  }

  public assembleFullUrl(isSc: boolean = false): string {
    return (
      (isSc ? determineBaseUrl() : determineBaseSSUrl()) +
      '/api/' +
      this.assembleEndpointUrl()
    );
  }

  public assembleFullGQLUrl(): string {
    return determineBaseUrl() + '/api/graphql/';
  }

  // Generic endpoint composition

  private addPathComponent(component: string | number): ApiRequest {
    this.pathComponents.push(component.toString());
    return this;
  }

  public withQueryString(
    queryString?: string | Record<string, any>
  ): ApiRequest {
    this.queryString =
      typeof queryString === 'object' ? toParams(queryString) : queryString;
    return this;
  }

  public withAction(apiAction: string): ApiRequest {
    return this.addPathComponent(apiAction);
  }

  // Request finalization
  public async get(
    options?: ApiMethodOptions,
    isSc: boolean = true
  ): Promise<any> {
    return await api.get(this.assembleFullUrl(isSc), options);
  }

  public async update(
    options?: ApiMethodOptions & { data: any },
    isSc: boolean = true
  ): Promise<any> {
    return await api.update(this.assembleFullUrl(isSc), options?.data, options);
  }

  public async create(
    options?: ApiMethodOptions & { data: any },
    isSc: boolean = true
  ): Promise<any> {
    return await api.create(this.assembleFullUrl(isSc), options?.data, options);
  }

  public async delete(isSc: boolean = true): Promise<any> {
    return await api.delete(this.assembleFullUrl(isSc));
  }

  // GQL specific api
  public async gqlMutate<T>(
    operationName: string,
    query: string,
    variables?: Record<string, any>
  ): Promise<T> {
    return (
      (await api.gql.mutate<T>(
        this.assembleFullGQLUrl(),
        gql`
          ${query}
        `,
        variables ?? {}
      )) as Record<string, any>
    )[operationName];
  }

  public async gqlGet<T>(
    operationName: string,
    query: string,
    variables?: Record<string, any>
  ): Promise<T> {
    return (
      (await api.gql.get<T>(
        this.assembleFullGQLUrl(),
        gql`
          ${query}
        `,
        variables ?? {}
      )) as Record<string, any>
    )[operationName];
  }

  public async gqlGetRaw<T>(
    query: string,
    variables?: Record<string, any>
  ): Promise<T> {
    return (await api.gql.get<T>(
      this.assembleFullGQLUrl(),
      gql`
        ${query}
      `,
      variables ?? {}
    )) as Record<string, any> as T;
  }

  // Paths
  public conversations(): ApiRequest {
    return this.addPathComponent('conversations');
  }

  public conversation(conversationId: string): ApiRequest {
    return this.addPathComponent('conversations').addPathComponent(
      conversationId
    );
  }

  public searchDoctors(): ApiRequest {
    return this.addPathComponent('v1').addPathComponent('search-doctors');
  }

  public text2Hpo(): ApiRequest {
    return this.addPathComponent('v1').addPathComponent('text2hpo');
  }

  public checkPreEligibility(): ApiRequest {
    return this.addPathComponent('pre_eligibility');
  }

  public pollPreEligibility(): ApiRequest {
    return this.addPathComponent('poll_eligibility');
  }

  public dashboard(path: string): ApiRequest {
    const res = this.addPathComponent('dashboard');
    if (path) {
      return res.addPathComponent(path);
    }
    return res;
  }

  public gene(path: string): ApiRequest {
    const res = this.addPathComponent('gene');
    if (path) {
      return res.addPathComponent(path);
    }
    return res;
  }
}

export const api = {
  ...genericRoutes,
  user: userRoutes,
  dashboard: dashboardRoutes,
  conversation: conversationRoutes,
  formResponse: formResponseRoutes,
  physician: physicianRoutes,
  order: orderRoutes,
  opportunity: opportunitiesRoutes,
  searchDiagnoses: searchDiagnosesRoutes,
  eligibility: eligibilityRoutes,
  gene: geneRoutes,
};

export default api;
