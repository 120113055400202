import {
  actions,
  afterMount,
  connect,
  kea,
  path,
  reducers,
  selectors,
} from 'kea';
import type { featureFlagLogicType } from './featureFlagLogicType';
import { analytics } from '@/helpers/analytics';
import {
  FEATURE_FLAGS,
  SEARCH_PARAM_FEATURE_FLAGS,
} from '@/lib/logics/featureFlags';
import posthog from 'posthog-js';
import { areValuesEmpty } from '@/helpers/utils';
import { router } from 'kea-router';

export type FeatureFlagsSet = {
  [flag: string]: boolean | string | undefined;
};

const eventsNotified: Record<string, boolean> = {};

function notifyFlagIfNeeded(
  flag: string,
  flagState: string | boolean | undefined
): void {
  if (!eventsNotified[flag]) {
    analytics.notifyFeatureFlag(flag, flagState);
    eventsNotified[flag] = true;
  }
}

function spyOnFeatureFlags(
  featureFlags: FeatureFlagsSet = {}
): FeatureFlagsSet {
  if (typeof window.Proxy !== 'undefined') {
    return new Proxy(
      {},
      {
        get(_, flag) {
          if (flag === 'toJSON') {
            return () => featureFlags;
          }
          const flagString: string = flag.toString();
          const flagState = featureFlags[flagString];
          notifyFlagIfNeeded(flagString, flagState);
          return flagState;
        },
      }
    );
  }
  // Fallback for IE11. Won't track "false" results.
  const flags: FeatureFlagsSet = {};
  for (const flag of Object.keys(featureFlags)) {
    Object.defineProperty(flags, flag, {
      get: function () {
        if (flag === 'toJSON') {
          return () => featureFlags;
        }
        notifyFlagIfNeeded(flag, true);
        return true;
      },
    });
  }
  return flags;
}

export const featureFlagLogic = kea<featureFlagLogicType>([
  path(['src', 'lib', 'featureFlagLogic']),
  connect(() => ({
    values: [router, ['searchParams']],
  })),
  actions({
    setFeatureFlags: (
      flags: string[],
      variants: Record<string, string | boolean>
    ) => ({ flags, variants }),
  }),
  reducers(({ cache }) => ({
    featureFlags: [
      spyOnFeatureFlags() as FeatureFlagsSet,
      { persist: true },
      {
        setFeatureFlags: (_, { variants }) => spyOnFeatureFlags(variants),
      },
    ],
    receivedFeatureFlags: [
      false,
      {
        setFeatureFlags: (_, { variants }) => {
          if (areValuesEmpty(variants)) {
            return false;
          }
          return true;
        },
      },
    ],
  })),
  afterMount(({ actions }) => {
    posthog.onFeatureFlags(actions.setFeatureFlags);
    posthog.setPersonPropertiesForFlags(
      {
        $current_url: window.location.toString(),
        $initial_current_url: window.location.toString(),
      },
      true
    );
  }),
  selectors(() => ({
    enableEligibilityPolling: [
      (s) => [s.featureFlags],
      (featureFlags) =>
        !!featureFlags?.[FEATURE_FLAGS.CHATPG_ELIGIBILITY_POLLING],
    ],
    showNotifications: [
      (s) => [s.featureFlags],
      (featureFlags) => !!featureFlags?.[FEATURE_FLAGS.NOTIFICATIONS],
    ],
    showEmailLaterFlow: [(s) => [s.searchParams], () => false],
  })),
]);
