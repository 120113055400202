import {
  FindingPathogenicities,
  FormResponseStatus,
  GeneticTestResult,
  GeneticTestVariantInputFormType,
  LabTest,
  PatientBiologicalSex,
  RelationshipToPatient,
  SelfReportedTestInputFormType,
} from '@/helpers/types';
import { SelectFieldItem } from '@/components/Fields/SelectField';

export const IS_DEV = import.meta.env.DEV;

export const IS_TEST = import.meta.env.VITE_ENV === 'test';
export const IS_STAGING =
  import.meta.env.PROD && import.meta.env.VITE_ENV === 'stg';
export const IS_PROD =
  import.meta.env.PROD && import.meta.env.VITE_ENV === 'prd';

export const CHATPG_URL =
  window.location.origin ?? 'https://chat.probablygenetic.com';

export const SENTRY_SPAN_DENY_LIST = [
  'rudderstack.com',
  'rudderlabs.com',
  'gravatar.com',
  'cloudfront.net/', // posthog reverse proxy
  'cookiebot.com',
  'posthog.com',
];

export const SENTRY_DENY_URLS_LIST = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /consentcdn\.cookiebot\.com/i,
  /posthog\.com/i,
  /rudderstack\.com/i,
];

export const SENTRY_IGNORE_ERRORS_LIST = [
  // Sentry community
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  'AbortError',
  'withrealtime/messaging',
  'Chartboost is not defined',
  "Can't find variable: Chartboost",
  // PG Custom
  'Multiple faces detected in the photo provided',
  'Unable to detect a face in the photo provided',
];

export const EMPTY_RELATIONSHIP_OPTION: SelectFieldItem = {
  key: '',
  value: '',
  label: 'Please select an option',
};
export const RELATIONSHIP_OPTIONS: SelectFieldItem[] = [
  {
    key: RelationshipToPatient.Self,
    value: RelationshipToPatient.Self,
    label: 'Myself',
  },
  {
    key: RelationshipToPatient.Parent,
    value: RelationshipToPatient.Parent,
    label: 'Parent',
  },
  {
    key: RelationshipToPatient.FamilyMember,
    value: RelationshipToPatient.FamilyMember,
    label: 'Family Member',
  },
];

export const COUNTRIES = [
  'United States',
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua & Deps',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Rep',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo {Democratic Rep}',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland {Republic}',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar, {Burma}',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'St Kitts & Nevis',
  'St Lucia',
  'Saint Vincent & the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const COUNTRIES_OPTIONS = COUNTRIES.map((country) => ({
  key: country,
  value: country,
  label: country,
}));

export const BIOLOGICAL_SEX_OPTIONS = [
  {
    key: PatientBiologicalSex.Female,
    value: PatientBiologicalSex.Female,
    label: 'Female',
  },
  {
    key: PatientBiologicalSex.Male,
    value: PatientBiologicalSex.Male,
    label: 'Male',
  },
];

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const STATE_OPTIONS = STATES.map((state) => ({
  key: state,
  value: state,
  label: state,
}));

export const SPECIALTIES = [
  [1, 'General Practice'],
  [2, 'General Surgery'],
  [3, 'Allergy/Immunology'],
  [4, 'Otolaryngology'],
  [5, 'Anesthesiology'],
  [6, 'Cardiovascular Disease (Cardiology)'],
  [7, 'Dermatology'],
  [8, 'Family Practice'],
  [9, 'Interventional Pain Management'],
  [10, 'Gastroenterology'],
  [11, 'Internal Medicine'],
  [12, 'Osteopathic Manipulative Medicine'],
  [13, 'Neurology'],
  [14, 'Neurosurgery'],
  [15, 'Speech Language Pathologist'],
  [16, 'Obstetrics & Gynecology'],
  [17, 'Hospice and Palliative Care'],
  [18, 'Ophthalmology'],
  [19, 'Oral Surgery (Dentist only)'],
  [20, 'Orthopedic Surgery'],
  [21, 'Clinical Cardiac Electrophysiology'],
  [22, 'Pathology'],
  [23, 'Sports Medicine'],
  [24, 'Plastic and Reconstructive Surgery'],
  [25, 'Physical Medicine and Rehabilitation'],
  [26, 'Psychiatry'],
  [27, 'Geriatric Psychiatry'],
  [28, 'Colorectal Surgery (Proctology)'],
  [29, 'Pulmonary Disease'],
  [30, 'Diagnostic Radiology'],
  [31, 'Anesthesiology Assistant'],
  [32, 'Thoracic Surgery'],
  [33, 'Urology'],
  [34, 'Chiropractic'],
  [35, 'Nuclear Medicine'],
  [36, 'Pediatric Medicine'],
  [37, 'Geriatric Medicine'],
  [38, 'Nephrology'],
  [39, 'Hand Surgery'],
  [40, 'Optometry'],
  [41, 'Certified Nurse Midwife'],
  [42, 'Certified Registered Nurse Anesthetist (CRNA)'],
  [43, 'Infectious Disease'],
  [44, 'Endocrinology'],
  [45, 'Podiatry'],
  [46, 'Nurse Practitioner'],
  [47, 'Individual Certified Orthotist'],
  [48, 'Individual Certified Prosthetist'],
  [49, 'Individual Certified Prosthetist-Orthotist'],
  [50, 'Clinical Psychologist'],
  [51, 'Audiologist'],
  [52, 'Physical Therapist in Private Practice'],
  [53, 'Rheumatology'],
  [54, 'Occupational Therapist in Private Practice'],
  [55, 'Registered Dietitian or Nutrition Professional'],
  [56, 'Pain Management'],
  [57, 'Peripheral Vascular Disease'],
  [58, 'Vascular Surgery'],
  [59, 'Cardiac Surgery'],
  [60, 'Addiction Medicine'],
  [61, 'Licensed Clinical Social Worker'],
  [62, 'Critical Care (Intensivists)'],
  [63, 'Hematology'],
  [64, 'Hematology-Oncology'],
  [65, 'Preventive Medicine'],
  [66, 'Maxillofacial Surgery'],
  [67, 'Neuropsychiatry'],
  [68, 'Medical Oncology'],
  [69, 'Surgical Oncology'],
  [70, 'Radiation Oncology'],
  [71, 'Emergency Medicine'],
  [72, 'Interventional Radiology'],
  [73, 'Optician'],
  [74, 'Physician Assistant'],
  [75, 'Gynecological Oncology'],
  [76, 'Pedorthic personnel'],
  [77, 'Sleep Medicine'],
  [78, 'Interventional Cardiology'],
  [79, 'Dentist'],
  [80, 'Hospitalist'],
  [81, 'Advanced Heart Failure and Transplant Cardiology'],
  [82, 'Medical Toxicology'],
];

SPECIALTIES.sort((a, b) =>
  String(a[1]).toUpperCase().localeCompare(String(b[1]).toUpperCase())
);

export const SPECIALTIES_OPTIONS = SPECIALTIES.map(([id, label]) => ({
  key: String(id),
  value: String(id),
  label,
}));

export const YESNODONTKNOW_OPTIONS = [
  {
    key: true,
    value: true,
    label: 'Yes',
  },
  {
    key: false,
    value: false,
    label: 'No',
  },
  {
    key: null,
    value: null,
    label: "I don't know",
  },
];

export const YESNO_OPTIONS = [
  {
    key: true,
    value: true,
    label: 'Yes',
  },
  {
    key: false,
    value: false,
    label: 'No',
  },
];

export const LAB_TEST_OPTIONS = [
  {
    key: LabTest.GENE_PANEL,
    value: LabTest.GENE_PANEL,
    label: 'Gene Panel',
  },
  {
    key: LabTest.WHOLE_EXOME_SEQUENCING,
    value: LabTest.WHOLE_EXOME_SEQUENCING,
    label: 'Whole Exome Sequencing',
  },
  {
    key: LabTest.WHOLE_GENOME_SEQUENCING,
    value: LabTest.WHOLE_GENOME_SEQUENCING,
    label: 'Whole Genome Sequencing',
  },
  {
    key: LabTest.BRAIN_MRI,
    value: LabTest.BRAIN_MRI,
    label: 'Brain MRI',
  },
  {
    key: LabTest.BRAIN_PET,
    value: LabTest.BRAIN_PET,
    label: 'Brain PET',
  },
  {
    key: LabTest.CHROMOSOMAL_MICROARRAY_ANALYSIS,
    value: LabTest.CHROMOSOMAL_MICROARRAY_ANALYSIS,
    label: 'Chromosomal Microarray Analysis',
  },
  {
    key: LabTest.EEG,
    value: LabTest.EEG,
    label: 'EEG',
  },
  {
    key: LabTest.FISH_TEST,
    value: LabTest.FISH_TEST,
    label: 'Fish Test',
  },
  {
    key: LabTest.KARYOTYPE_GENETIC_TEST,
    value: LabTest.KARYOTYPE_GENETIC_TEST,
    label: 'Karyotype Genetic Test',
  },
  {
    key: LabTest.LUMBAR_PUNCTURE,
    value: LabTest.LUMBAR_PUNCTURE,
    label: 'Lumbar Puncture',
  },
  {
    key: LabTest.METHYLATION_TEST,
    value: LabTest.METHYLATION_TEST,
    label: 'Methylation Test',
  },
  {
    key: LabTest.RNA_SEQUENCING,
    value: LabTest.RNA_SEQUENCING,
    label: 'RNA Sequencing',
  },
  {
    key: LabTest.SLEEP_STUDY,
    value: LabTest.SLEEP_STUDY,
    label: 'Sleep Study',
  },
  {
    key: LabTest.OTHER_GENETIC_TEST,
    value: LabTest.OTHER_GENETIC_TEST,
    label: 'Other Genetic Test',
  },
];

export const RESULTS_OPTIONS = [
  {
    key: GeneticTestResult.Positive,
    value: GeneticTestResult.Positive,
    label: 'Positive',
  },
  {
    key: GeneticTestResult.Negative,
    value: GeneticTestResult.Negative,
    label: 'Negative',
  },
  {
    key: GeneticTestResult.Uncertain,
    value: GeneticTestResult.Uncertain,
    label: 'Uncertain',
  },
];

export const PATHOGENICITIES_OPTIONS = [
  {
    key: FindingPathogenicities.Pathogenic,
    value: FindingPathogenicities.Pathogenic,
    label: 'Pathogenic',
  },
  {
    key: FindingPathogenicities.LikelyPathogenic,
    value: FindingPathogenicities.LikelyPathogenic,
    label: 'Likely pathogenic',
  },
  {
    key: FindingPathogenicities.Vus,
    value: FindingPathogenicities.Vus,
    label: 'VUS',
  },
  {
    key: FindingPathogenicities.LikelyBenign,
    value: FindingPathogenicities.LikelyBenign,
    label: 'Likely benign',
  },
  {
    key: FindingPathogenicities.Benign,
    value: FindingPathogenicities.Benign,
    label: 'Benign',
  },
];
export const INITIAL_TEST_FORM_DATA: SelfReportedTestInputFormType = {
  panelName: '',
  panelNameNotSure: true,
  testingYear: null,
  testingYearNotSure: true,
  testType: null,
  result: null,
  resultNotSure: true,
  variants: [],
};
export const INITIAL_VARIANT_FORM_DATA: GeneticTestVariantInputFormType = {
  geneSymbol: '',
  geneSymbolNotSure: true,
  cdnaChange: '',
  cdnaChangeNotSure: true,
  pathogenicity: null,
  pathogenicityNotSure: true,
};

export const WORDS_OF_ENCOURAGEMENT = [
  'Every detail about your journey as a patient is important, hang in there while we finish reviewing carefully.',
  'Every detail you provide is important, hang in there while we finish reviewing carefully. ',
  'We take your symptoms very seriously and are analyzing carefully - this may take a few moments.',
  'Reviewing your provided symptom information takes a few moments, thanks so much for your patience.',
  'Reviewing your provided symptom information takes a few moments, hang in there!',
  'Just a few more moments as we review this information about your symptoms! ',
  'It may take a few moments to review your response thoroughly. We appreciate your patience. ',
];

export const FORM_RESPONSE_STATUS_OPTIONS = [
  {
    key: FormResponseStatus.InReview,
    value: FormResponseStatus.InReview,
    label: 'In Review',
  },
  {
    key: FormResponseStatus.Pending,
    value: FormResponseStatus.Pending,
    label: 'Pending',
  },
  {
    key: FormResponseStatus.Approved,
    value: FormResponseStatus.Approved,
    label: 'Approved',
  },
  {
    key: FormResponseStatus.Rejected,
    value: FormResponseStatus.Rejected,
    label: 'Rejected',
  },
  {
    key: FormResponseStatus.PermanentlyRejected,
    value: FormResponseStatus.PermanentlyRejected,
    label: 'Rejected',
  },
  {
    key: FormResponseStatus.OptedOut,
    value: FormResponseStatus.OptedOut,
    label: 'Opted Out',
  },
  {
    key: FormResponseStatus.Fraudulent,
    value: FormResponseStatus.Fraudulent,
    label: 'Rejected',
  },
];

export const MEDICAL_DISCLAIMER = `This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on our website!`;

export const PROFILE_SYMPTOMS_TOOLTIP =
  'These are potential symptoms that were determined during the conversation section. This is not a professionally determined list intended for medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.';

export const PROFILE_SELF_REPORTED_TOOLTIP =
  'These are diagnoses that were self-reported on a previous form. This is not a professionally determined list intended for medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.';

export const DOWNLOAD_RAW_DATA_TOOLTIP =
  'You can now download your raw genetic data from our platform. However, please note that without specialized software or genetics background, you may not be able to fully understand or interpret your data.';

export const POA_INFO_TEXT =
  'If you’re submitting for another individual or a child over 18, proof of medical power of attorney will be required if the application is approved for testing.';

export const ESTIMATED_SECONDS_PER_QUESTION = 32; // 32 seconds

export const ROMANIZED_NUMBERS = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
];

export const FAMILY_MEMBERS = [
  'Father',
  'Mother',
  'Sister',
  'Brother',
  'Son',
  'Daughter',
  'Aunt',
  'Uncle',
  'Niece',
  'Nephew',
  'Half-sibling',
  'Grandfather',
  'Grandmother',
  'Cousin',
  'Other',
];

export const FAMILY_MEMBERS_OPTIONS = FAMILY_MEMBERS.map((f) => ({
  key:
    f === 'Half-sibling'
      ? 'Half_sibling'.toLocaleUpperCase()
      : f.toLocaleUpperCase(),
  value: f.toLocaleUpperCase(),
  label: f,
}));

export const ETHNICITY_OPTIONS: SelectFieldItem[] = [
  {
    key: 'NATIVE_AMERICAN',
    label: 'American Indian or Alaska Native',
    value: 'NATIVE_AMERICAN',
  },
  { key: 'ASIAN', label: 'Asian', value: 'asian' },
  { key: 'BLACK', label: 'Black or African American', value: 'black' },
  { key: 'HISPANIC', label: 'Hispanic or Latino', value: 'hispanic' },
  {
    key: 'MIDDLE_EASTERN',
    label: 'Middle Eastern or North African',
    value: 'MIDDLE_EASTERN',
  },
  {
    key: 'PACIFIC_ISLANDER',
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'PACIFIC_ISLANDER',
  },
  { key: 'WHITE', label: 'White', value: 'WHITE' },
  { key: 'OTHER', label: 'Other', value: 'OTHER' },
];
