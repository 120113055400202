import { useEffect, useState } from 'react';

export interface UseTimeoutStateProps {
  ms: number;
}

export function useTimeoutState({ ms }: UseTimeoutStateProps): {
  show: boolean;
  msLeft: number;
} {
  const [msLeft, setMsLeft] = useState<number>(ms);

  useEffect(() => {
    const tick = setInterval(() => {
      setMsLeft((msLeft) => msLeft - 1000);
    }, 1000);
    return () => clearInterval(tick);
  }, []);

  return { show: msLeft <= 0, msLeft };
}
