import { Error404 } from '@/pages/Error404/Error404';
import { combineUrl, router } from 'kea-router';
import { ROMANIZED_NUMBERS } from '@/helpers/constants';
import { LoadedScene, Params } from '@/lib/logics/scene.types';
import { analytics } from '@/helpers/analytics';
import { IoChatbox, IoMedical, IoPerson } from 'react-icons/io5';
import { ReactNode } from 'react';

export const emptySceneParams = {
  params: {},
  searchParams: {},
  hashParams: {},
};

export enum SceneKey {
  Conversation = 'conversation',
  ConversationSummary = 'conversation_summary',
  Cookies = 'cookies',
  Dashboard = 'dashboard',
  Error404 = 'error',
  Email = 'email',
  EmailRedirectMerge = 'email_redirect_merge',
  ExternalResource = 'external_resource',
  Intake = 'intake',
  MedicalHistory = 'medical_history',
  Opportunities = 'opportunities',
  Order = 'order',
  Orders = 'orders',
  PersonalIdentifying = 'personal_identifying',
  Profile = 'profile',
  SignIn = 'sign_in',
  Survey = 'survey',
  ThankYou = 'thank_you',
}

/**
 * Titles for use in tracking a user's progress.
 * i.e. the `Stepper` component.
 *
 * The order of the flow is determined by this list
 */
export const SceneTitles: Record<
  string,
  {
    label: string;
    intermediate?: boolean;
    icon?: ReactNode;
  }
> = {
  // These four are part of the "official" flows
  [SceneKey.Conversation]: {
    label: 'Conversation',
    icon: <IoChatbox size={20} className="shrink-0" />,
  },
  [SceneKey.MedicalHistory]: {
    label: 'Medical History',
    icon: <IoMedical size={20} className="shrink-0" />,
  },
  [SceneKey.PersonalIdentifying]: {
    label: 'Personal Details',
    icon: <IoPerson size={20} className="shrink-0" />,
  },
  [SceneKey.ConversationSummary]: {
    label: 'Finalize',
    icon: <div>Finalize</div>,
  },
  [SceneKey.ThankYou]: {
    label: 'Thank You',
  },
};

const preserveParams = (nextUrl: string): string => {
  const routerLogic = router.findMounted();
  if (!routerLogic) {
    return nextUrl;
  }

  // If phId hash exists, identify on chatpg to preserve identities across cross-domains.
  const { phId } = routerLogic.values.searchParams;
  const nextSearch = routerLogic.values.searchParams;
  if (phId) {
    analytics.identify(phId, {});
    delete nextSearch['phId'];
  }

  return combineUrl(nextUrl, nextSearch, routerLogic.values.hashParams).url;
};

export const urls: Record<string, (a?: any, b?: any) => string> = {
  home: () => preserveParams('https://www.probablygenetic.com'),
  [SceneKey.Intake]: () => preserveParams(`/`),
  [SceneKey.SignIn]: () => preserveParams('/sign-in'),
  [SceneKey.PersonalIdentifying]: (id: string) =>
    preserveParams(`/identifying/${id}`),
  [SceneKey.Conversation]: (id: string) =>
    preserveParams(`/conversation/${id}`),
  [SceneKey.MedicalHistory]: (id: string) => preserveParams(`/medical/${id}`),
  [SceneKey.ConversationSummary]: (id: string) =>
    preserveParams(`/conversation-summary/${id}`),
  [SceneKey.ThankYou]: (id: string) => preserveParams(`/thank-you/${id}`),
  [SceneKey.Profile]: () => preserveParams(`/profile`),
  [SceneKey.Opportunities]: () => preserveParams(`/opportunities`),
  [SceneKey.Dashboard]: () => preserveParams(`/dashboard`),
  [SceneKey.Order]: (formResponseId: string) =>
    preserveParams(`/order/${formResponseId}`),
  [SceneKey.Orders]: () => preserveParams(`/orders`),
  [SceneKey.Survey]: (id: string) => preserveParams(`/survey/${id}`),
  [SceneKey.ExternalResource]: (id: string) =>
    preserveParams(`/resource/external/claim/${id}`),
  [SceneKey.Cookies]: () => preserveParams('/cookies'),
  [SceneKey.Email]: (id: string) => preserveParams(`/email/${id}`),
  [SceneKey.EmailRedirectMerge]: (id: string) =>
    preserveParams(`/email-redirect/${id}`),
};

export const preloadedScenes: Record<string, LoadedScene> = {
  [SceneKey.Error404]: {
    name: SceneKey.Error404,
    component: Error404,
    sceneParams: emptySceneParams,
  },
};

export const redirects: Record<
  string,
  | string
  | ((params: Params, searchParams: Params, hashParams: Params) => string)
> = {
  '/': () => '/v2', // This should auto redirect to v2
  [urls.profile()]: () => '/v2/dashboard',
  [urls.orders()]: () => '/v2/dashboard',
  [urls.conversation(':id')]: '/v2',
  [urls.intake()]: '/v2',
  [urls.sign_in()]: '/v2/sign-in',

  [urls.personal_identifying(':id')]: '/v2',
  [urls.conversation(':id')]: '/v2',
  [urls.conversation_summary(':id')]: '/v2',
  [urls.medical_history(':id')]: '/v2',
  [urls.thank_you(':id')]: '/v2',

  [urls.profile()]: '/v2/dashboard',
  [urls.opportunities()]: '/v2/dashboard',
  [urls.dashboard()]: '/v2/dashboard',
  [urls.order(':id')]: '/v2/dashboard',
  [urls.orders()]: '/v2/dashboard',
  [urls.survey(':id')]: '/v2',
  [urls.external_resource(':id')]: '/v2/dashboard',
  [urls.cookies(':id')]: '/v2',
  [urls.email(':id')]: '/v2',
  [urls.email_redirect_merge(':id')]: '/v2',
};

export const routes: Record<string, SceneKey> = {
  [urls.intake()]: SceneKey.Intake,
  [urls.sign_in()]: SceneKey.SignIn,
  [urls.personal_identifying(':id')]: SceneKey.PersonalIdentifying,
  [urls.conversation(':id')]: SceneKey.Conversation,
  [urls.conversation_summary(':id')]: SceneKey.ConversationSummary,
  [urls.medical_history(':id')]: SceneKey.MedicalHistory,
  [urls.thank_you(':id')]: SceneKey.ThankYou,
  [urls.profile()]: SceneKey.Profile,
  [urls.opportunities()]: SceneKey.Opportunities,
  [urls.dashboard()]: SceneKey.Dashboard,
  [urls.order(':id')]: SceneKey.Order,
  [urls.orders()]: SceneKey.Orders,
  [urls.survey(':id')]: SceneKey.Survey,
  [urls.external_resource(':id')]: SceneKey.ExternalResource,
  [urls.cookies(':id')]: SceneKey.Cookies,
  [urls.email(':id')]: SceneKey.Email,
  [urls.email_redirect_merge(':id')]: SceneKey.EmailRedirectMerge,
};

// Edit these to change the order of pages
export const orderedFlowUrls = Object.fromEntries(
  Object.keys(SceneTitles).map((route, index) => [
    ROMANIZED_NUMBERS[index],
    urls[route],
  ])
);

export const orderedStepUrlsToShow = Object.values(orderedFlowUrls).filter(
  (func) => func.name !== SceneKey.ThankYou
);

export const appScenes: Record<SceneKey, any> = {
  [SceneKey.Intake]: () => import('@/pages/Intake/Intake'),
  [SceneKey.SignIn]: () => import('@/pages/Auth/SignIn/SignIn'),
  [SceneKey.PersonalIdentifying]: () =>
    import('@/pages/PersonalIdentifying/PersonalIdentifying'),
  [SceneKey.Error404]: () => import('@/pages/Error404/Error404'),
  [SceneKey.Email]: () =>
    import('@/pages/Auth/EmailCollection/EmailCollection'),
  [SceneKey.EmailRedirectMerge]: () =>
    import('@/pages/Auth/EmailCollection/EmailRedirectMerge'),
  [SceneKey.Conversation]: () => import('@/pages/Conversation/Conversation'),
  [SceneKey.MedicalHistory]: () =>
    import('@/pages/MedicalHistory/MedicalHistory'),
  [SceneKey.ConversationSummary]: () =>
    import('@/pages/ConversationSummary/ConversationSummary'),
  [SceneKey.Profile]: () => import('@/pages/Profile/ProfileScene'),
  [SceneKey.Opportunities]: () => import('@/pages/Opportunities/Opportunities'),
  [SceneKey.ThankYou]: () => import('@/pages/ThankYou/ThankYou'),
  [SceneKey.Dashboard]: () => import('@/pages/Dashboard/Dashboard'),
  [SceneKey.Order]: () => import('@/pages/Order/Order'),
  [SceneKey.Orders]: () => import('@/pages/Order/Orders'),
  [SceneKey.Survey]: () => import('@/pages/Surveys/Survey'),
  [SceneKey.ExternalResource]: () =>
    import('@/pages/External/Resource/ExternalResource'),
  [SceneKey.Cookies]: () => import('@/pages/Cookies/Cookies'),
};
