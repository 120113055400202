import { FormResponseQuery, queries } from '@/lib/api/formResponse.queries';
import {
  AcceptInformedConsentInput,
  AddressInputType,
  CompleteTestingQuestionsInput,
  DeletePhotoInput,
  FormResponseFilterInput,
  FormResponseType,
  HpoItem,
  InitFormResponseInputData,
  ProcessResultInput,
  UpdateGeneticTestingInput,
  UpdateQuestionValuesInputData,
  UploadPhotoInput,
  UpdateSelfReportedDiagnosesInput,
  UpdateSelfReportedGeneticHistoryInput,
  DeleteFormResponse,
} from '@/helpers/types';
import { ApiRequest } from '@/lib/api/api';

export const formResponseRoutes = {
  async [FormResponseQuery.InitFormResponse](
    variables: InitFormResponseInputData
  ): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.InitFormResponse,
        queries.initFormResponse,
        variables
      )
    ).formResponse;
  },
  async [FormResponseQuery.UpdateQuestionValues](variables: {
    input: UpdateQuestionValuesInputData;
  }): Promise<FormResponseType> {
    return await new ApiRequest().gqlMutate<FormResponseType>(
      FormResponseQuery.UpdateQuestionValues,
      queries.updateQuestionValues,
      variables
    );
  },
  async [FormResponseQuery.FormResponseState](variables: {
    formResponseId: string;
  }): Promise<FormResponseType> {
    return await new ApiRequest().gqlGet<FormResponseType>(
      FormResponseQuery.FormResponseState,
      queries.formResponseState,
      variables
    );
  },
  async [FormResponseQuery.DeleteFormResponse](variables: {
    input: DeleteFormResponse;
  }): Promise<Partial<FormResponseType>> {
    return await new ApiRequest().gqlMutate<Partial<FormResponseType>>(
      FormResponseQuery.DeleteFormResponse,
      queries.deleteFormResponse,
      variables
    );
  },
  async [FormResponseQuery.UpdateAddress](variables: {
    input: AddressInputType;
  }): Promise<AddressInputType> {
    return await new ApiRequest().gqlMutate<AddressInputType>(
      FormResponseQuery.UpdateAddress,
      queries.updateAddress,
      variables
    );
  },
  async text2Hpo(text: string): Promise<HpoItem[]> {
    return (
      await new ApiRequest().text2Hpo().create(
        {
          data: { text },
        },
        false
      )
    ).items;
  },
  async [FormResponseQuery.UpdateGeneticTesting](variables: {
    input: UpdateGeneticTestingInput;
  }): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.UpdateGeneticTesting,
        queries.updateGeneticTesting,
        variables
      )
    ).formResponse;
  },
  async [FormResponseQuery.UploadPhoto](variables: {
    input: UploadPhotoInput;
  }): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.UploadPhoto,
        queries.uploadPhoto,
        variables
      )
    ).formResponse;
  },
  async [FormResponseQuery.DeletePhoto](variables: {
    input: DeletePhotoInput;
  }): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.DeletePhoto,
        queries.deletePhoto,
        variables
      )
    ).formResponse;
  },
  async [FormResponseQuery.CompleteTestingQuestions](variables: {
    input: CompleteTestingQuestionsInput;
  }): Promise<Partial<FormResponseType>> {
    return await new ApiRequest().gqlMutate<Partial<FormResponseType>>(
      FormResponseQuery.CompleteTestingQuestions,
      queries.completeTestingQuestions,
      variables
    );
  },
  async [FormResponseQuery.AcceptInformedConsent](variables: {
    input: AcceptInformedConsentInput;
  }): Promise<Partial<FormResponseType>> {
    return await new ApiRequest().gqlMutate<Partial<FormResponseType>>(
      FormResponseQuery.AcceptInformedConsent,
      queries.acceptInformedConsent,
      variables
    );
  },
  async [FormResponseQuery.ProcessResult](variables: {
    input: ProcessResultInput;
  }): Promise<FormResponseType> {
    return await new ApiRequest().gqlMutate<FormResponseType>(
      FormResponseQuery.ProcessResult,
      queries.processResult,
      variables
    );
  },
  async [FormResponseQuery.FormResponses](variables: {
    filter?: Partial<FormResponseFilterInput>;
    offset?: number;
    before?: string;
    after?: string;
    first?: number;
    last?: number;
  }): Promise<FormResponseType[]> {
    return (
      (
        await new ApiRequest().gqlGet<{ edges: { node: FormResponseType }[] }>(
          FormResponseQuery.FormResponses,
          queries.formResponses,
          variables
        )
      )?.edges?.map(({ node }) => node) ?? []
    );
  },
  async [FormResponseQuery.UpdateSelfReportedDiagnoses](variables: {
    input: UpdateSelfReportedDiagnosesInput;
  }): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.UpdateSelfReportedDiagnoses,
        queries.updateSelfReportedDiagnoses,
        variables
      )
    ).formResponse;
  },
  async [FormResponseQuery.UpdateSelfReportedGeneticHistory](variables: {
    input: UpdateSelfReportedGeneticHistoryInput;
  }): Promise<FormResponseType> {
    return (
      await new ApiRequest().gqlMutate<{ formResponse: FormResponseType }>(
        FormResponseQuery.UpdateSelfReportedGeneticHistory,
        queries.updateSelfReportedGeneticHistory,
        variables
      )
    ).formResponse;
  },
};
