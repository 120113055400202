import {
  actions,
  BindLogic,
  events,
  kea,
  path,
  reducers,
  selectors,
  useMountedLogic,
  useValues,
} from 'kea';
import { sceneLogic } from '@/lib/logics/sceneLogic';
import { appScenes } from '@/helpers/routes';
import { LoadedScene } from '@/lib/logics/scene.types';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { SpinnerOverlay } from '@/components/SpinnerOverlay';
import { ReactNode } from 'react';
import { featureFlagLogic } from '@/lib/logics/featureFlagLogic';
import type { appLogicType } from './appType';
import { userLogic } from '@/lib/logics/userLogic';
import { cookiesLogic } from '@/lib/logics/cookiesLogic';
import { HighVolumeOverlay } from '@/components/HighVolumeOverlay';

export const appLogic = kea<appLogicType>([
  path(['src', 'app', 'app']),
  actions({
    enableDelayedSpinner: true,
    ignoreFeatureFlags: true,
    ignoreLongRequests: true,
  }),
  reducers({
    showingDelayedSpinner: [false, { enableDelayedSpinner: () => true }],
    featureFlagsTimedOut: [false, { ignoreFeatureFlags: () => true }],
    spinnerTimedOut: [false, { ignoreLongRequests: () => true }],
  }),
  selectors({
    showApp: [
      (s) => [
        userLogic.selectors.userLoading,
        userLogic.selectors.user,
        featureFlagLogic.selectors.receivedFeatureFlags,
        s.featureFlagsTimedOut,
      ],
      (userLoading, user, receivedFeatureFlags, featureFlagsTimedOut) => {
        return (
          (!userLoading || user) &&
          (receivedFeatureFlags || featureFlagsTimedOut)
        );
      },
    ],
  }),
  events(({ actions, cache }) => ({
    afterMount: () => {
      cache.spinnerTimeout = window.setTimeout(
        () => actions.enableDelayedSpinner(),
        1000
      );
      cache.featureFlagTimeout = window.setTimeout(
        () => actions.ignoreFeatureFlags(),
        3000
      );
      cache.longRequestsTimeout = window.setTimeout(
        () => actions.ignoreLongRequests(),
        10000
      );
    },
    beforeUnmount: () => {
      window.clearTimeout(cache.spinnerTimeout);
      window.clearTimeout(cache.featureFlagTimeout);
      window.clearTimeout(cache.longRequestsTimeout);
    },
  })),
]);

export function App() {
  useMountedLogic(cookiesLogic);
  useMountedLogic(featureFlagLogic);
  useMountedLogic(userLogic);
  const { showApp, showingDelayedSpinner, spinnerTimedOut } =
    useValues(appLogic);
  useMountedLogic(sceneLogic({ scenes: appScenes }));

  if (showApp) {
    return (
      <>
        <LoadedSceneLogics />
        <AppScene />
      </>
    );
  }

  // Ask user to try again
  if (spinnerTimedOut) {
    return <HighVolumeOverlay visible={showingDelayedSpinner} />;
  }

  return <SpinnerOverlay sceneLevel visible={showingDelayedSpinner} />;
}

function LoadedSceneLogic({ scene }: { scene: LoadedScene }): null {
  if (!scene.logic) {
    throw new Error('Loading scene without a logic');
  }
  useMountedLogic(scene.logic(scene.paramsToProps?.(scene.sceneParams)));
  return null;
}

function LoadedSceneLogics() {
  const { loadedScenes } = useValues(sceneLogic);

  return (
    <>
      {Object.entries(loadedScenes)
        .filter(([, { logic }]) => !!logic)
        .map(([key, loadedScene]) => (
          <LoadedSceneLogic key={key} scene={loadedScene} />
        ))}
    </>
  );
}

function AppScene(): JSX.Element | null {
  const { scene, activeLoadedScene, sceneParams, loadedScenes } =
    useValues(sceneLogic);
  const { showingDelayedSpinner } = useValues(appLogic);

  let sceneElement: ReactNode;
  if (scene && scene in loadedScenes) {
    const { component: SceneComponent, paramsToProps } = loadedScenes[scene];
    const componentProps = paramsToProps?.(sceneParams) ?? {};

    sceneElement = <SceneComponent {...componentProps} />;
  } else {
    sceneElement = <SpinnerOverlay visible={showingDelayedSpinner} />;
  }

  return (
    <ErrorBoundary>
      {activeLoadedScene?.logic ? (
        <BindLogic
          logic={activeLoadedScene.logic}
          props={activeLoadedScene.paramsToProps?.(sceneParams) || {}}
        >
          {sceneElement}
        </BindLogic>
      ) : (
        sceneElement
      )}
    </ErrorBoundary>
  );
}

export default App;
