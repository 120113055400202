import {
  ButtonProps as NextButtonProps,
  Button as NextButton,
} from '@heroui/react';
import cn from '@/helpers/cn';
import { forwardRef, RefAttributes } from 'react';
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';
import TextareaAutosize from 'react-textarea-autosize';

export interface ButtonProps extends Omit<NextButtonProps, 'size'> {
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps & RefAttributes<HTMLTextAreaElement>
>((props, ref) => {
  return <TextareaAutosize minRows={1} {...props} ref={ref} />;
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size = 'md', className, ...props }, ref) => {
    const sizes = {
      sm: '',
      md: '',
      lg: '',
      xl: 'text-xl h-14',
    };
    if (!['sm', 'md', 'lg'].includes(size)) {
      return (
        <NextButton
          {...props}
          ref={ref}
          size="lg"
          className={cn('font-bold', className, sizes?.[size])}
        />
      );
    }
    const _size = size as NextButtonProps['size'];
    return (
      <NextButton
        {...props}
        ref={ref}
        size={_size}
        className={cn('font-bold', className)}
      />
    );
  }
);
