import dayjs from 'dayjs';
import { ConversationLogicProps } from '@/pages/Conversation/conversationDataLogic';
import {
  Conversation,
  FormResponseType,
  PatientResponse,
  PatientResponsePayload,
  PayloadType,
  PhysicianResponse,
  PhysicianType,
  QuestionResponse,
  SelfReportedGeneticHistoryType,
  SelfReportedTestInputFormType,
} from '@/helpers/types';
import transform from 'lodash.transform';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import isArray from 'lodash.isarray';
import isObject from 'lodash.isobject';
import {
  INITIAL_TEST_FORM_DATA,
  INITIAL_VARIANT_FORM_DATA,
  IS_DEV,
  IS_STAGING,
  IS_TEST,
  PATHOGENICITIES_OPTIONS,
} from '@/helpers/constants';
import pick from 'lodash.pick';
import posthog, { Survey } from 'posthog-js';

export function determineBaseUrl(): string {
  // TODO: Replace with doppler envs - https://app.asana.com/0/1206547468042690/1206547927648907/f
  if (IS_TEST) {
    return `http://localhost:4201`; // Edge of network. Required for MSW
  }
  if (IS_DEV) {
    return '';
  }
  if (IS_STAGING) {
    return 'https://demo-sc.probablygenetic.com';
  }
  return 'https://symptom-checker.probablygenetic.com';
}

export function determineBaseSSUrl(): string {
  // TODO: Replace with doppler envs - https://app.asana.com/0/1206547468042690/1206547927648907/f
  if (IS_TEST) {
    return `http://localhost:4200`; // Edge of network. Required for MSW
  }
  if (IS_DEV) {
    return 'http://localhost:8000';
  }
  if (IS_STAGING) {
    return 'https://demo-portal.probablygenetic.com';
  }
  return 'https://app.probablygenetic.com';
}

export function toParams(
  obj: Record<string, any>,
  explodeArrays: boolean = false,
  includeQueryStartString = false
): string {
  if (!obj) {
    return '';
  }

  function handleVal(val: any): string {
    if (dayjs.isDayjs(val)) {
      return encodeURIComponent(val.format('YYYY-MM-DD'));
    }
    val = typeof val === 'object' ? JSON.stringify(val) : val;
    return encodeURIComponent(val);
  }

  const searchParams = Object.entries(obj)
    // eslint-disable-next-line eqeqeq
    .filter((item) => item[1] != undefined && item[1] != null)
    .reduce((acc, [key, val]) => {
      /**
       *  query parameter arrays can be handled in two ways
       *  either they are encoded as a single query parameter
       *    a=[1, 2] => a=%5B1%2C2%5D
       *  or they are "exploded" so each item in the array is sent separately
       *    a=[1, 2] => a=1&a=2
       **/
      if (explodeArrays && Array.isArray(val)) {
        val.forEach((v) => acc.push([key, v]));
      } else {
        acc.push([key, val]);
      }

      return acc;
    }, [] as [string, any][])
    .map(([key, val]) => `${key}=${handleVal(val)}`)
    .join('&');

  if (!searchParams) {
    return '';
  }

  return `${includeQueryStartString ? '?' : ''}${searchParams}`;
}

export function createConversationLogicKey(props: ConversationLogicProps) {
  if (!props.id) {
    throw new Error(
      `Cannot initialize conversation logic with blank ids: Conversation ${props.id}}`
    );
  }
  return String(props.id);
}

export function generateResponseToQuestion(
  question: QuestionResponse,
  response: PatientResponsePayload['response']
): PatientResponse {
  return {
    uuid: 'new',
    formResponseId: question.formResponseId,
    created: dayjs().format(),
    type: PayloadType.Response,
    payload: {
      type: question.payload.type as PatientResponse['payload']['type'],
      response,
    },
    summary: {},
  };
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCookie(name: string): string {
  if (!document.cookie) {
    return '';
  }
  const token = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));

  if (token.length === 0) {
    return '';
  }
  return decodeURIComponent(token[0].split('=')[1]);
}

export const camelize = (obj: Record<string, any>) =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    // @ts-expect-error
    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const snakify = (obj: Record<string, any>) =>
  transform(obj, (acc, value, key, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);
    // @ts-expect-error
    acc[snakeKey] = isObject(value) ? snakify(value) : value;
  });

export function parseConversation(conversation: Conversation): Conversation {
  // TODO: Messages should return sorted, but sometimes they don't
  return {
    ...conversation,
    messages: [...(conversation?.messages ?? [])].sort(
      ({ orderIndex: aOI }, { orderIndex: bOI }) => (aOI ?? 0) - (bOI ?? 0)
    ),
  };
}

export function parseFormResponse(formResponse: any): FormResponseType {
  // Parse api form response to frontend form reponse type
  return {
    ...formResponse,
    ...formResponse?.conversation?.formResponse,
    id: Number(formResponse.pk),
    referenceId:
      formResponse.referenceId ??
      formResponse.conversation?.formResponse?.referenceId,
  };
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validateDateOfBirth(dateOfBirth: string): string | undefined {
  const currentYear = new Date().getFullYear();
  let dateOfBirthError: string | undefined;

  if (!dateOfBirth) {
    dateOfBirthError = 'Must provide date of birth';
  } else {
    const dob = new Date(dateOfBirth);
    const year = dob.getFullYear();
    if (isNaN(dob.getTime()) || year < 1900 || year > currentYear) {
      dateOfBirthError = 'Date of birth must be a valid date';
    }
  }
  return dateOfBirthError;
}

export async function getJSONOrThrow(response: Response): Promise<any> {
  try {
    const jsonData = await response.json();
    return camelize(jsonData);
  } catch (e) {
    return { statusText: response.statusText, status: response.status };
  }
}

export function checkGqlResponse(jsonData: Record<any, any>): {
  ok: boolean;
  error?: string;
} {
  const errors = jsonData.errors || [];
  if (errors.length > 0) {
    return {
      ok: false,
      error: errors
        .map(({ message }: { message: string }) => message)
        .join('. '),
    };
  }
  return {
    ok: true,
  };
}

export function capitalizeEachWord(
  s: string,
  { isName = false }: { isName?: boolean } = {}
): string {
  if (!s) {
    return '';
  }

  // Don't normalize (force-lowercase) when we're dealing with names.
  const normalizerFunc = isName
    ? (s: string) => s
    : (chunk: string) => chunk.trim().toLowerCase();

  return s
    .split(' ')
    .map(normalizerFunc)
    .filter((chunk) => !!chunk)
    .map((chunk) => capitalizeFirstLetter(chunk))
    .join(' ');
}

// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '';
}

export function removeEmptyValues(object: Record<any, any>) {
  return Object.fromEntries(
    Object.entries(object).filter(
      ([_, v]) => !(v === undefined || v === null || v === '')
    )
  );
}

export function areValuesEmpty(object?: Record<any, any>): boolean {
  return Object.values(object ?? {}).every(
    (x) => x === undefined || x === null || x === ''
  );
}

export function isValidDate(d: string) {
  return !isNaN(new Date(d).getTime());
}

export function transformToPhysicianModel(
  physician: PhysicianResponse
): Omit<PhysicianType, 'id'> {
  const names = physician.fullName.split(' ');
  return {
    address1: physician.address,
    address2: null,
    city: physician.city,
    state: physician.state,
    npi: physician.npi,
    phoneNumber: physician.phoneNumber,
    firstName: names[0],
    lastName: names[1],
    organization: physician.organization,
    zipCode: null,
    specialties: physician.specialtyIds.map((id, index) => ({
      id,
      name: physician.specialtyNames[index],
    })) as unknown[],
  } as Omit<PhysicianType, 'id'>;
}

export function getPersonFirstAndLastNames(
  _fullName: string | undefined | null = ''
): [string, string] {
  const fullName = _fullName?.trim();
  if (!fullName) {
    return ['', ''];
  }
  const names = fullName.split(' ');
  const lastNames = names.slice(1).join(' ');

  return [names[0], lastNames];
}

export function isValidFullName(name: string) {
  const [firstName, lastName] = getPersonFirstAndLastNames(name);
  return firstName.trim() && lastName.trim();
}

/**
 * Creates a new array with unique entries based on a provided key.
 * @param {array} arr - Input array
 * @param {string} key - key to match
 */
export function getUniqueListBy(arr: any[], key: string) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

/**
 * Essentially _.uniqWith.
 * Taken from: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_uniqwith
 * @param {array} arr - Input array
 * @param {function} fn - Comparator function
 */
export function getUniqueArrayWith(arr: any[], fn: (a: any, b: any) => any) {
  if (arr.length <= 1) return arr;
  return arr.filter(
    (element, index) => arr.findIndex((step) => fn(element, step)) === index
  );
}

export function cleanSelfReportedTests(
  tests: Partial<SelfReportedTestInputFormType>[]
): Partial<SelfReportedTestInputFormType>[] {
  return (
    tests?.map((test) =>
      pick(
        {
          ...test,
          variants: test.variants?.map((variant) =>
            pick(variant, Object.keys(INITIAL_VARIANT_FORM_DATA))
          ),
        },
        Object.keys(INITIAL_TEST_FORM_DATA)
      )
    ) ?? []
  );
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result)?.split(',')[1]);
    reader.onerror = reject;
  });

// https://stackoverflow.com/questions/4550505/getting-a-random-value-from-a-javascript-array
export function pickElementFromArrayRandomly(arr: any[]): any {
  return arr[Math.floor(Math.random() * arr.length)];
}

export async function getSurveys(): Promise<Survey[]> {
  return new Promise((resolve) => {
    posthog.getActiveMatchingSurveys((surveys) => {
      resolve(surveys);
    }, true);
  });
}

export async function openPdf(url: string): Promise<void> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const opened = window.open(blobUrl, '_blank');

    if (!opened) {
      // just redirect user to the url if the popups/new tabs are blocked.
      window.location.assign(blobUrl);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

// https://www.w3resource.com/javascript-exercises/javascript-math-exercise-105.php#:~:text=JavaScript%20Code%3A,representation%20of%20the%20given%20number.&text=trunc(n%20%2F%20100)%5D%20%2B,parseInt(n%20%2F%201000)).
/**
 * Function to convert a given number into words.
 * @param {number} n - The number to be converted into words.
 * @returns {string} - The word representation of the given number.
 */
export function convertNumberToWord(n: number): string {
  if (n < 0) return '';

  // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
  const single_digit = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  const double_digit = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];
  const below_hundred = [
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  if (n === 0) return 'Zero';

  // Recursive function to translate the number into words
  function translate(inner_n: number) {
    let word = '';
    if (inner_n < 10) {
      word = single_digit[n] + ' ';
    } else if (inner_n < 20) {
      word = double_digit[n - 10] + ' ';
    } else if (inner_n < 100) {
      const rem = translate(inner_n % 10);
      word = below_hundred[(n - (n % 10)) / 10 - 2] + ' ' + rem;
    } else if (inner_n < 1000) {
      word =
        single_digit[Math.trunc(inner_n / 100)] +
        ' Hundred ' +
        translate(inner_n % 100);
    } else if (inner_n < 1000000) {
      word =
        translate(parseInt(String(inner_n / 1000))).trim() +
        ' Thousand ' +
        translate(inner_n % 1000);
    } else if (inner_n < 1000000000) {
      word =
        translate(parseInt(String(inner_n / 1000000))).trim() +
        ' Million ' +
        translate(inner_n % 1000000);
    } else {
      word =
        translate(parseInt(String(inner_n / 1000000000))).trim() +
        ' Billion ' +
        translate(inner_n % 1000000000);
    }
    return word;
  }

  // Get the result by translating the given number
  return translate(n).trim();
}

export function isUUID(str?: string) {
  // Regular expression for UUID validation
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return regex.test(str?.trim() ?? '');
}

export function isValidPhoneNumber(phone?: string | null) {
  return phone && phone.length > 3;
}

// Truncate the text in the middle and replace the truncated text with an ellipsis.
// Use this when the beginning and end of a string are important or distinctive.
export function truncateMiddleOfString(
  fullStr: string,
  strLen: number,
  separator: string = '...'
) {
  if (fullStr.length <= strLen) return fullStr;

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)
  );
}

export function getPathogenicitiesLabel(key?: string | null) {
  if (!key) {
    return;
  }
  return PATHOGENICITIES_OPTIONS.find((p) => key === p.key)?.label;
}

export function makeGeneticHistoryHumanReadable(
  history: SelfReportedGeneticHistoryType
): string {
  const relative = capitalizeFirstLetter(history.relative.toLowerCase());
  const gene = history.geneSymbol.toUpperCase();
  if (history.pathogenicity) {
    return `${relative} with a ${gene} ${getPathogenicitiesLabel(
      history.pathogenicity
    )} variant`;
  }
  return `${relative} with a ${gene} variant`;
}
