import { Spinner } from '@heroui/react';
import cn from '@/helpers/cn';
import { useTimeoutState } from '@/helpers/useTimeoutState';

interface SpinnerOverlayProps {
  sceneLevel?: boolean;
  visible?: boolean;
}

export function SpinnerOverlay({ sceneLevel, visible }: SpinnerOverlayProps) {
  const { show: showText } = useTimeoutState({ ms: 5000 });

  return visible ? (
    <div
      className={cn(
        'w-screen h-screen flex flex-col gap-6 justify-center items-center',
        sceneLevel && 'before:bg-default-200'
      )}
    >
      {showText && (
        <p className="px-4 text-foreground-700 text-lg text-center">
          Just a moment while we load the page...
        </p>
      )}
      <Spinner size="lg" />
    </div>
  ) : (
    <></>
  );
}
