import {
  actions,
  connect,
  defaults,
  events,
  kea,
  listeners,
  path,
  reducers,
  selectors,
} from 'kea';
import type { userLogicType } from './userLogicType';
import {
  FormResponseType,
  LLMType,
  RelationshipToPatient,
  UserAndFormResponsesType,
} from '@/helpers/types';
import { loaders } from 'kea-loaders';
import api from '@/lib/api/api';
import { analytics } from '@/helpers/analytics';
import { urls } from '@/helpers/routes';
import { router } from 'kea-router';
import { windowValues } from 'kea-window-values';
import { isUUID, parseFormResponse } from '@/helpers/utils';
import posthog from 'posthog-js';
import { featureFlagLogic } from '@/lib/logics/featureFlagLogic';

export const userLogic = kea<userLogicType>([
  path(['src', 'lib', 'userLogic']),
  defaults({
    user: null as UserAndFormResponsesType | null,
  }),
  connect(() => ({
    values: [featureFlagLogic, ['showEmailLaterFlow']],
  })),
  windowValues({
    isSmallScreen: (window: Window) => window.innerWidth < 640, // tailwind's sm breakpoint
  }),
  actions({
    signIn: (email: string, password: string) => ({ email, password }),
    signOut: (andReroute?: boolean) => ({ andReroute }),
    restorePassword: (email: string, returnUrl: string) => ({
      email,
      returnUrl,
    }), // request forgot password email
    resetPassword: (email: string, password: string, token: string) => ({
      email,
      password,
      token,
    }),
    requestOtp: (email: string) => ({ email }),
    verifyOtp: (email: string, otp: string) => ({ email, otp }),
    checkAuth: true,
  }),
  reducers(() => ({
    authCookieSet: [
      false as boolean,
      { persist: true },
      {
        signOutSuccess: () => false,
        restorePassword: () => false,
        signInSuccess: () => true,
        getMeSuccess: (_, { user }) => !!user,
      },
    ],
    user: {
      signOutSuccess: () => null,
      restorePassword: () => null,
    },
  })),
  loaders(() => ({
    user: {
      signIn: async ({ email, password }, breakpoint) => {
        await breakpoint(1);
        const response = await api.user.signIn({
          username: email,
          password,
        });
        breakpoint();
        analytics.identify(response.id, {
          email: response.username,
        });
        posthog.reloadFeatureFlags();
        return response;
      },
      signOut: async ({ andReroute }, breakpoint) => {
        await breakpoint(1);
        await api.user.signOut();
        analytics.unidentify();
        breakpoint();

        if (andReroute) {
          // hard redirect to intake page so that Cloudflare redirects us to ChatPG V2
          window.location.href = urls.intake();
        }
        return null;
      },
      getMe: async (_, breakpoint) => {
        await breakpoint(1);
        try {
          const response = await api.dashboard.user.me();
          breakpoint();
          analytics.identify(response.id, {
            email: response.username,
          });

          return response;
        } catch {
          /* Noop we simply set user to null */
          analytics.unidentify();
          return null;
        }
      },
    },
  })),
  listeners(({ actions }) => ({
    restorePassword: async ({ email, returnUrl }, breakpoint) => {
      await breakpoint(1);
      await api.user.restorePassword({ email, returnUrl });
      breakpoint();
    },
    resetPassword: async ({ email, password, token }, breakpoint) => {
      await breakpoint(1);
      await api.user.resetPassword({ email, password, token });
      breakpoint();
    },
    requestOtp: async ({ email }, breakpoint) => {
      await breakpoint(1);
      await api.dashboard.user.requestOtp(email);
      breakpoint();
    },
    verifyOtp: async ({ email, otp }, breakpoint) => {
      await breakpoint(1);
      await api.dashboard.user.verifyOtp(email, otp);
      breakpoint();
    },
    checkAuth: async (_, breakpoint) => {
      const { email } = await api.dashboard.user.isAuth();
      if (email) {
        actions.getMe({});
      }
    },
  })),
  selectors(() => ({
    userLoggedIn: [
      (s) => [s.user, s.showEmailLaterFlow],
      (user, showEmailLaterFlow) => {
        if (showEmailLaterFlow && isUUID(user?.username)) {
          return false;
        }
        return user ? Object.keys(user).length > 0 : false;
      },
    ],
    formResponsesWithOrders: [
      (s) => [s.user],
      (user): FormResponseType[] => {
        // If there are form responses with orders, prioritize showing those first
        return (user?.formResponses ?? [])
          .filter(
            ({ orderReferenceId }: FormResponseType) => !!orderReferenceId
          )
          .map(parseFormResponse);
      },
    ],
    formResponses: [
      (s) => [s.formResponsesWithOrders, s.user],
      (formResponsesWithOrders, user): FormResponseType[] => {
        // If there are form responses with orders, prioritize showing those first
        if (formResponsesWithOrders.length > 0) {
          return formResponsesWithOrders;
        }
        return (user?.formResponses ?? [])
          .filter(({ conversation, deleted }) => !deleted && conversation)
          .map(parseFormResponse)
          .sort((a, b) => Number(a.id ?? 0) - Number(b.id ?? 0));
      },
    ],
    // This will be used to create a conversation on so that we don't create multiple FR's
    lastFormResponseWithoutConversation: [
      (s) => [s.user],
      (user): FormResponseType | null => {
        return (
          (user?.formResponses ?? [])
            .filter(({ conversation, deleted }) => !deleted && !conversation)
            .map(parseFormResponse)
            .sort((a, b) => Number(a.id ?? 0) - Number(b.id ?? 0))
            .at(-1) ?? null
        );
      },
    ],
    incompleteFormResponses: [
      (s) => [s.formResponses],
      (formResponses): FormResponseType[] =>
        formResponses.filter(
          ({ completedTestingQuestions }) => !completedTestingQuestions
        ),
    ],
    lastIncompleteFormResponse: [
      (s) => [s.incompleteFormResponses],
      (incompleteFormResponses) =>
        incompleteFormResponses.slice(-1)?.[0] ?? null,
    ],
    completeFormResponses: [
      (s) => [s.formResponses],
      (formResponses): FormResponseType[] =>
        formResponses.filter(
          ({ completedTestingQuestions }) => !!completedTestingQuestions
        ),
    ],
    lastCompleteFormResponse: [
      (s) => [s.completeFormResponses],
      (completeFormResponses): FormResponseType | null =>
        completeFormResponses.slice(-1)?.[0] ?? null,
    ],
  })),
  events(({ actions }) => ({
    afterMount: () => {
      actions.checkAuth();
    },
  })),
  listeners(({ values }) => ({
    getMeSuccess: async (_, breakpoint) => {
      await breakpoint(100);
      // If conversation doesn't exist on a complete form response, we're dealing with a SC1 legacy FR that has an order
      // but not a conversation. Create a conversation if it doesn't exist for this form response so that
      // conversationDataLogic and formResponseDataLogic can be initialized and consumed.
      if (
        values.lastCompleteFormResponse &&
        !values.lastCompleteFormResponse.conversation?.id
      ) {
        await api.conversation.create({
          conversation: {
            formResponseId: values.lastCompleteFormResponse.referenceId,
            relationshipToPatient:
              values.lastCompleteFormResponse.customerRelationshipToPatient ===
              'child'
                ? RelationshipToPatient.Parent
                : RelationshipToPatient.Self,
          },
          llmType: LLMType.Text,
        });
        breakpoint();
      }
    },
  })),
]);
