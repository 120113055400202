import {
  FieldValueTypeModelType,
  FormResponseReferralTypeModelType,
  OrderKitTypeModelType,
  OrderTypeModelType,
  PatientResourceTypeModelType,
  PatientTypeModelType,
  PersonTypeModelType,
  PhysicianTypeModelType,
  QuestionTypeModelType,
  SampleTypeModelType,
  SelectedHpoTypeModelType,
  SelfReportedGeneticTestTypeModelType,
  UserTypeModelType,
} from '@pg-web/api';
import { ActualFileObject, FilePondFile, FilePondInitialFile } from 'filepond';
import dayjs from 'dayjs';
import {
  FieldsToFix,
  StandardizedUspsAddress,
  SuggestedAction,
} from '@/helpers/googleMapsAddress';

export enum PayloadType {
  Response = 'response',
  Question = 'question',
}

export enum QuestionType {
  MultipleChoice = 'multiple_choice',
  Text = 'text',
  End = 'end', // Signals a conversation has ended
  Image = 'image', // face scans to add later
}

export enum LLMType {
  MultipleChoice = 'multiple_choice',
  Text = 'text',
  MultipleChoiceAndText = 'multiple_choice_and_text',
}

export interface ResponseBase {
  uuid: string;
  formResponseId: string;
  created: string;
  orderIndex?: number;
  type: PayloadType;
  analysis?: Record<string, string>;
  summary: {
    summary?: string;
  };
  llm?: Record<string, any>;
}

export type PatientResponsePayload =
  | {
      type: QuestionType.MultipleChoice;
      response: string; // option value
    }
  | {
      type: QuestionType.Text;
      response: string;
    }
  | {
      type: QuestionType.Image;
      response: string; // Can send as base64 url string for now
      // file_src: string // Ideally will send it to S3 bucket or similar
    };

export interface PatientResponse extends ResponseBase {
  type: PayloadType.Response;
  forQuestionId?: string;
  payload: PatientResponsePayload;
}

export interface QuestionMultipleChoicePayload {
  type: QuestionType.MultipleChoice;
  label: string;
  value: string;
  component?: string;
  options: {
    value: string;
    label: string;
  }[];
}

export interface QuestionEndConversationPayload {
  type: QuestionType.End;
  label: string;
  value: string;
  component?: string;
  options: {
    value: string;
    label: string;
  }[];
}

export interface QuestionTextPayload {
  type: QuestionType.Text;
  label: string;
  value: string;
  component?: string;
}

export interface QuestionImagePayload {
  type: QuestionType.Image;
  label: string;
  value: string;
  component?: string;
}

export type QuestionPayload =
  | QuestionMultipleChoicePayload
  | QuestionTextPayload
  | QuestionImagePayload
  | QuestionEndConversationPayload;

export interface QuestionResponse extends ResponseBase {
  type: PayloadType.Question;
  payload: QuestionPayload;
}

export type MessageType = PatientResponse | QuestionResponse;

export function isPatientMessage(
  message: PatientResponse | QuestionResponse
): message is PatientResponse {
  return (<PatientResponse>message).type === PayloadType.Response;
}

export interface Conversation {
  id: string;
  referenceId: string;
  formResponseId: string;
  formResponse: FormResponseType;
  messages: MessageType[];
  conversationEnded: string;
  relationshipToPatient: RelationshipToPatient;
}

export interface ConversationApiResponse {
  conversation: Conversation;
}

export enum RelationshipToPatient {
  Self = 'self',
  FamilyMember = 'family_member',
  Parent = 'parent',
}

type RelationshipToPatientTextResponseObj = Record<
  RelationshipToPatient,
  string
>;
export const RelationshipToPatientTextResponse: RelationshipToPatientTextResponseObj =
  {
    [RelationshipToPatient.Self]: 'I am the patient.',
    [RelationshipToPatient.FamilyMember]: 'The patient is a family member.',
    [RelationshipToPatient.Parent]: 'I am the parent of the patient.',
  };

export const FullNameTextResponse: RelationshipToPatientTextResponseObj = {
  [RelationshipToPatient.Self]: 'My name is',
  [RelationshipToPatient.FamilyMember]: "The patient's name is",
  [RelationshipToPatient.Parent]: "My child's name is",
};

export interface IntakeFormData {
  email: string;
  otp?: string;
  tosConsent: boolean;
  personFullName: string;
  patientFullName: string;
  relationshipToPatient: RelationshipToPatient;
}

export interface EmailLaterIntakeFormData {
  tosConsent: boolean;
  personFullName: string;
  patientFullName: string;
  relationshipToPatient: RelationshipToPatient;
}

export interface EmailLaterStepFormData {
  personFullName: string;
  email: string;
}

export interface SignInData {
  email: string;
  otp?: string;
}

export interface GenericApiResponse {
  ok: boolean;
  error: string;
}

export interface UpdatePersonInputData {
  formResponseId: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}

export interface UpdatePatientInputData {
  formResponseId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ageOfOnset?: number;
  biologicalSex: string;
  parentsRelated: boolean;
  hematologicMalignancy: boolean;
  boneMarrowStemCellTransplant: boolean;
  ethnicities: string[];
}

export interface InitFormResponseInputData {
  formName: string;
  queryTags: string;
  anonymousId?: boolean;
}

export interface UpdateQuestionValuesInputData {
  formResponseId: string;
  questionId: string;
  values: string[];
}

export interface GqlError {
  code?: string;
  message?: string;
  type?: string;
}

export interface GqlResponse {
  errors?: GqlError[];
  data?: unknown;
  status?: number;
}

export interface GqlRequest {
  query?: string;
  variables?: Record<string, unknown>;
}

export interface GqlResult {
  request: GqlRequest;
  response: GqlResponse;
}

export type UserType = UserTypeModelType;
export type PatientType = PatientTypeModelType;
export type PersonType = PersonTypeModelType;
export type OpportunityType = FormResponseReferralTypeModelType;
export type PatientResourceType = PatientResourceTypeModelType;

export enum OpportunityReferralEnum {
  Physician = 'PHYSICIAN',
  Pdf = 'PDF',
  Url = 'URL',
}

export type PhysicianType = Pick<
  PhysicianTypeModelType,
  | 'address1'
  | 'address2'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'firstName'
  | 'lastName'
  | 'npi'
  | 'phoneNumber'
  | 'organization'
  | 'isPrimary'
> & { id?: string; specialties?: { id: string; name: string }[] };

export type FormResponseQuestion = Partial<QuestionTypeModelType>;

export type FormResponseFieldValue = Partial<FieldValueTypeModelType>;

export type OrderType = Partial<OrderTypeModelType>;

export type OrderKitType = Partial<OrderKitTypeModelType>;

export type OrderKitSampleType = Partial<SampleTypeModelType>;

export interface FormResponseType {
  id: string;
  referenceId: string;
  orderReferenceId?: string;
  person?: PersonType;
  patient?: PatientType;
  submittedDate?: number;
  submittedTimestamp?: number;
  result?: FormResponseResult;
  status?: FormResponseStatus;
  completedTestingQuestions?: boolean;
  conversation?: Conversation;
  ageOfOnset?: number;
  shippingAddress?: AddressInputType | null;
  questions?: FormResponseQuestion[];
  fieldValues?: FormResponseFieldValue[];
  selectedHpos?: SelectedHpoTypeModelType[];
  selfReportedTests?: SelfReportedTestInputFormType[];
  facialPhotos?: FacialPhoto[];
  hasOpportunities?: boolean;
  isTraining?: boolean;
  deleted?: boolean;
  opportunities?: OpportunityType[];
  form?: {
    name: string;
    displayName: string;
    optInDisclaimer: string;
    showKitReturnAgreement: boolean;
    showPreTestGcAgreement: boolean;
  };
  customerRelationshipToPatient?: string; // legacy. Now use conversation.relationshipToPatient
  acceptedInformedConsent?: boolean;
  order?: OrderType;
  selfReportedDiagnoses?: SelfReportedDiagnosesType[];
  selfReportedGeneticHistory?: SelfReportedGeneticHistoryType[];
}

export interface FacialPhoto {
  id: string;
  photo: string;
}

export enum FormName {
  ChatPG = 'chatpg',
}

export interface GqlRequestBody {
  operationName: string;
  query: string;
  variables: Record<string, any>;
}

export interface AddressInputType {
  // `id` might not exist yet if the user is new.
  // In that case, we should be creating a new address instance.
  id?: string;
  address1: string;
  address2: string;
  city: string;
  exUnitedStates: boolean;
  formResponseId: string;
  state: string;
  zipCode: string;
}

export interface AddressFormType
  extends Omit<AddressInputType, 'exUnitedStates'> {
  country: string;
}

export type FilesType = Array<
  FilePondFile | FilePondInitialFile | ActualFileObject | Blob | string
>;

export interface SearchPhysiciansInput {
  query: string;
  specialty_id: string;
  state: string;
}

export interface PhysicianResponse {
  address: string;
  city: string;
  fullName: string;
  id: string;
  npi: number | undefined;
  organization: string | undefined;
  phoneNumber: string;
  specialtyIds: number[];
  specialtyNames: string[];
  state: string;
}

export interface SearchPhysiciansResponse {
  doctors: PhysicianResponse[];
}

export type PatientInformationFormType = Pick<
  PatientType,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'biologicalSex'
  | 'parentsRelated'
  | 'hematologicMalignancy'
  | 'boneMarrowStemCellTransplant'
>;

export type PersonInformationFormType = Pick<
  PersonType,
  'id' | 'firstName' | 'lastName' | 'phone' | 'email'
>;

// TODO: GraphQL forces all caps enums, but the Django models
//    are in title case (i.e. Male, Female). Change this to reflect actual
//    reality on the Django model side for proper validation.
export enum PatientBiologicalSex {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
}

export enum ViewMode {
  View = 'view',
  Edit = 'edit',
  InConversation = 'in-conversation', // should only be rendered in interactable compatible components
}

export interface FieldProps {
  label?: string;
  testId?: string;
  description?: string;
  error?: React.ReactNode;
  showErrors?: boolean;
  isRequired?: boolean;
  className?: string;
  viewMode?: ViewMode;
  isLoading?: boolean;
  formErrors?: ServerInvalidFieldsType;
}

export interface CreatePhysicianInput {
  formResponseId: any;
  npi?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  faxNumber?: string | null;
  organization?: string | null;
  address1?: string | null;
  address2?: string | null;
  zipCode?: string | null;
  state?: string | null;
  city?: string | null;
  specialtyNames?: string[];
}

export interface SetPrimaryPhysicianInput {
  patientId: string;
  physicianId: string;
}

export interface RemovePhysicianInput {
  formResponseId: string;
  physicianId: string;
}

export type ContactInformationFormType = Pick<
  PersonType,
  'id' | 'firstName' | 'lastName' | 'phone'
> & {
  shippingAddress: AddressInputType;
  relationshipToPatient: RelationshipToPatient;
};

export interface HpoItem {
  hpoId: string;
  hpoName: string;
  hpoDescription: string;
}

export interface HpoQuestionsAndValues {
  otherPhenotypeText: {
    question: FormResponseQuestion | null;
    values: FormResponseFieldValue[];
  };
  searchedHPOs: {
    question: FormResponseQuestion | null;
    values: HpoItem[];
  };
}

export type SelfReportedTestType =
  Partial<SelfReportedGeneticTestTypeModelType>;

export enum GeneticTestResult {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Uncertain = 'UNCERTAIN',
}

export enum FindingPathogenicities {
  Pathogenic = 'PATHOGENIC',
  LikelyPathogenic = 'LIKELY_PATHOGENIC',
  Vus = 'VUS',
  LikelyBenign = 'LIKELY_BENIGH', // Existing typo in FindingPathogenicities
  Benign = 'BENIGH', // Existing typo in FindingPathogenicities
}

export enum LabTest {
  BRAIN_MRI = 'BRAIN_MRI',
  BRAIN_PET = 'BRAIN_PET',
  CHROMOSOMAL_MICROARRAY_ANALYSIS = 'CHROMOSOMAL_MICROARRAY_ANALYSIS',
  EEG = 'EEG',
  FISH_TEST = 'FISH_TEST',
  GENE_PANEL = 'GENE_PANEL',
  KARYOTYPE_GENETIC_TEST = 'KARYOTYPE_GENETIC_TEST',
  LUMBAR_PUNCTURE = 'LUMBAR_PUNCTURE',
  METHYLATION_TEST = 'METHYLATION_TEST',
  RNA_SEQUENCING = 'RNA_SEQUENCING',
  SLEEP_STUDY = 'SLEEP_STUDY',
  WHOLE_EXOME_SEQUENCING = 'WHOLE_EXOME_SEQUENCING',
  WHOLE_GENOME_SEQUENCING = 'WHOLE_GENOME_SEQUENCING',
  OTHER_GENETIC_TEST = 'OTHER_GENETIC_TEST',
}

export interface GeneticTestVariantInputFormType {
  geneSymbol: string;
  geneSymbolNotSure: boolean;
  cdnaChange: string;
  cdnaChangeNotSure: boolean;
  pathogenicity: FindingPathogenicities | null;
  pathogenicityNotSure: boolean;
}

export interface SelfReportedTestInputFormType {
  testType: LabTest | null | undefined;
  testingYear: number | null;
  testingYearNotSure: boolean;
  panelName: string;
  panelNameNotSure: boolean;
  result: GeneticTestResult | null;
  resultNotSure: boolean;
  variants: Partial<GeneticTestVariantInputFormType>[];
}

export interface ConsentInputFormType {
  acceptedGeneticCounselingSession: boolean;
  acceptedKitReturn: boolean;
  acceptedTermsOfService: boolean;
  acceptedInformedConsent: boolean;
}

export interface UpdateGeneticTestingInput {
  formResponseId: FormResponseType['referenceId'];
  tests: Partial<SelfReportedTestInputFormType>[];
}

export interface Gene {
  id: string;
  name: string;
  symbol: string;
}

export interface UploadPhotoInput {
  formResponseId: FormResponseType['referenceId'];
  photo: string;
}

export interface DeletePhotoInput {
  formResponseId: FormResponseType['referenceId'];
  photoId: string;
}

export interface AnalyticsFormResponsePayload {
  formName: string;
  formResponseId: string;
  result?: string;
  isIframe?: boolean; // TODO: not implemented
}

export interface CompleteTestingQuestionsInput {
  formResponseReferenceId: string;
}

export interface AcceptInformedConsentInput {
  formResponseId: string;
}

export interface DownloadReportInput {
  formResponseId: string;
}

export interface DownloadDataInput {
  formResponseId: string;
  sampleId: string;
}

export interface DownloadableLinkType {
  url: string;
}

export interface ProcessResultInput {
  formResponseReferenceId: FormResponseType['referenceId'];
}

export enum FormResponseResult {
  Waitlist = 'Waitlist',
  TrainingResponse = 'Training Response',
  NotInUnitedStates = 'Not In United States',
}

export enum FormResponseStatus {
  InReview = 'In Review',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  PermanentlyRejected = 'Permanently Rejected',
  OptedOut = 'Opted Out',
  Fraudulent = 'Fraudulent',
}

export interface FormResponseFilterInput {
  completed: boolean;
  formResponseIds: string[];
}

export enum OrderStatus {
  PendingPreTestConsult = 'pending-pre-test-consult',
  PendingApproval = 'pending-approval',
  PWNApproved = 'pwn-approved',
  NeedsKit = 'needs-kit',
  ManualLaboratoryRequest = 'manual-laboratory-request',
  KitMailed = 'kit-mailed',
  SamplesReceived = 'samples-received',
  SampleQc = 'sample-qc',
  ExomeQc = 'exome-qc',
  SequencingComplete = 'sequencing-complete',
  AnalysisComplete = 'analysis-complete',
  PendingConsultScheduling = 'pending-consult-scheduling',
  ConsultScheduled = 'consult-scheduled',
  ReportReady = 'report-ready',
}

export interface DiagnosisType {
  id?: string;
  monarchId?: string;
  // In search results `name` will be populated, but internally `displayName`
  // is what we will mainly use.
  name?: string;
  displayName?: string;
  description?: string;
}

export interface SelfReportedDiagnosesType {
  id: string;
  diagnosis: DiagnosisType;
  monarchId?: string;
}

export interface SearchDiagnosesInput {
  search: string;
  limit?: number;
  offset?: number;
}

export interface SearchDiagnosesResponseItem {
  // This id is what a monarchId will refer to.
  id: string;
  name: string;
  description: string;
}

export type SearchDiagnosesResponse = SearchDiagnosesResponseItem[];

export interface UpdateSelfReportedDiagnosesInput {
  formResponseId: string;
  diagnoses: DiagnosisType[];
}

export type SurveyResponse = {
  $survey_id: string;
  $survey_response?: any;
} & Record<string, any>;

export interface ClaimOpportunityInput {
  formResponseReferenceId: string;
  patientResourceId: string;
}

export enum UserProgressBarStepStatus {
  InProgress = 'in_progress',
  Complete = 'complete',
  Disabled = 'disabled',
  MissingInformation = 'missing_information',
}

export interface NotificationType {
  id: string;
  timestamp: dayjs.Dayjs;
  title: string;
  description?: string;
  read?: boolean;
  // When a user receives this notification, will the notification
  // drawer automatically open?
  forceDrawerOpen?: boolean;
}

export enum InboxTabType {
  All = 'all',
  Unread = 'unread',
}

export enum RelativeInput {
  Mother = 'MOTHER',
  Father = 'FATHER',
  Brother = 'BROTHER',
  Sister = 'SISTER',
  Grandmother = 'GRANDMOTHER',
  Grandfather = 'GRANDFATHER',
  Other = 'OTHER',
}

export interface SelfReportedGeneticHistoryType {
  id?: number;
  relative: RelativeInput;
  geneSymbol: string;
  pathogenicity: FindingPathogenicities | null;
}

export interface UpdateSelfReportedGeneticHistoryInput {
  formResponseId: string;
  geneticHistory: SelfReportedGeneticHistoryType[];
}

export enum CheckpointAction {
  Summary = 'summary',
}

export interface CheckpointType {
  nodeIndex: number;
  iterationIndex: number;
  cumulativeIterationIndex?: number;
  checkpointIndex: number;
  action: CheckpointAction;
}

export interface LLMConfigType {
  config: Record<string, any>;
  nodeIndex: number;
  iterationIndex: number;
  cumulativeIterationIndex?: number;
  checkpoints?: CheckpointType[];
  maxIterationsCount?: number;
  minIterationsCount?: number;
}

export enum SmartFilterStage {
  CONVERSATION = 'conversation',
  MEDICAL_INFO = 'medical_info',
  PERSONAL_INFO = 'personal_info',
  SUMMARY = 'summary',
}

export enum InteractableShowMode {
  // Use this to hide the yes/no buttons and directly show the input field.
  None = 'none',
  Default = 'default',
  Yes = 'yes',
  No = 'no',
}

export enum InteractableComponentName {
  AlreadyDiagnosedInteractableInput = 'AlreadyDiagnosedInteractableInput',
  GeneticHistoryInteractableInput = 'GeneticHistoryInteractableInput',
  RelationshipToPatientInteractableInput = 'RelationshipToPatientInteractableInput',
  PreviousTestsInteractableInput = 'PreviousTestsInteractableInput',
  FullNameInteractableInput = 'FullNameInteractableInput',
  IntroductionAndTermsInteractableInput = 'IntroductionAndTermsInteractableInput',
  AgeOfOnsetInteractableInput = 'AgeOfOnsetInteractableInput',
}

export interface UserFilterInput {
  email: string;
}

export interface DeleteFormResponse {
  formResponseId: string;
}

export interface AddressToConfirm {
  oldAddress: AddressInputType;
  newFormattedAddress: string | null;
  newStandardizedUspsAddress: StandardizedUspsAddress;
  fieldsToFix?: FieldsToFix;
  suggestedAction: SuggestedAction;
}

export interface UserAndFormResponsesType {
  id?: number;
  username: string;
  firstName?: string;
  lastName?: string;

  formResponses?: FormResponseType[];
}

export type ServerInvalidFieldsType = Record<string, string[]>;
export type ServerInvalidRequestType = Record<
  string,
  ServerInvalidFieldsType | undefined
>;
