import { KeaPlugin, resetContext } from 'kea';
import { loadersPlugin } from 'kea-loaders';
import { combineUrl, routerPlugin } from 'kea-router';
import { formsPlugin } from 'kea-forms';
import { localStoragePlugin } from 'kea-localstorage';
import { windowValuesPlugin } from 'kea-window-values';
import {
  IS_DEV,
  SENTRY_DENY_URLS_LIST,
  SENTRY_IGNORE_ERRORS_LIST,
  SENTRY_SPAN_DENY_LIST,
} from '@/helpers/constants';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { initializeRudderStack } from '@/helpers/rudderstack';
import { initializeCookieBot } from '@/helpers/cookies';
import { uuidv7 } from 'posthog-js/lib/src/uuidv7';
// import { initializeHotjar } from '@/helpers/hotjar';

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_PROJECT_API_KEY;
const POSTHOG_PROXY_URL = import.meta.env.VITE_POSTHOG_PROXY_URL;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV;

interface InitStateProps {
  state?: Record<string, any>;
  routerHistory?: any;
  routerLocation?: any;
  beforePlugins?: KeaPlugin[];
}

export function initState({
  beforePlugins,
  routerLocation,
  routerHistory,
}: InitStateProps) {
  resetContext(
    {
      plugins: [
        ...(beforePlugins || []),
        routerPlugin({
          history: routerHistory,
          location: routerLocation,
        }),
        formsPlugin(),
        localStoragePlugin({
          prefix: 'pg',
          separator: '_',
        }),
        windowValuesPlugin({ window }),
        loadersPlugin({
          onFailure({ error, logic, reducerKey, actionKey }) {
            console.error({
              message: error.message,
              actionKey,
              path: logic.pathString,
            });
          },
        }),
      ],
    },
    true
  );
}

function initAnalytics() {
  // Rudderstack
  initializeRudderStack();

  // Posthog
  if (POSTHOG_API_KEY) {
    const distinctId =
      combineUrl(window.location.search).searchParams.phId || uuidv7();

    posthog.init(POSTHOG_API_KEY, {
      api_host: POSTHOG_PROXY_URL,
      ui_host: 'https://us.posthog.com',
      autocapture: {
        element_allowlist: [],
        css_selector_allowlist: ['[ph-autocapture]'],
      },
      disable_surveys: !window.location.pathname.includes('survey'),
      bootstrap: {
        distinctID: distinctId,
      },
      loaded: async () => {
        posthog.identify(
          distinctId,
          {
            $current_url: window.location.toString(),
          },
          {
            $initial_current_url: window.location.toString(),
          }
        );
      },
      advanced_disable_feature_flags_on_first_load: true,
      capture_pageview: true,
      session_recording: {
        maskTextSelector: '.sensitive',
      },
    });
  }

  // Sentry
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV || 'development',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.sessionTimingIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['patient-experience-app'],
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
      ],
      tracesSampleRate: IS_DEV ? 1.0 : 0.5,
      profilesSampleRate: IS_DEV ? 1.0 : 0.5,
      sendDefaultPii: true,
      beforeSendSpan(span) {
        if (SENTRY_SPAN_DENY_LIST.some((s) => span.description?.includes(s))) {
          return null;
        }
        return span;
      },
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/symptom-checker\.probablygenetic\.com\/api/,
      ],
      ignoreErrors: SENTRY_IGNORE_ERRORS_LIST,
      denyUrls: SENTRY_DENY_URLS_LIST,
    });
  }
}

export function initLibraries() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
}

export function initApp() {
  initState({});
  initLibraries();
  initAnalytics();
  initializeCookieBot({});
  // initializeHotjar();
}
