import {
  Conversation,
  ConversationApiResponse,
  LLMType,
} from '@/helpers/types';
import { parseConversation } from '@/helpers/utils';
import { ApiRequest } from '@/lib/api/api';
import { axios } from './axios';

function getUrl(part: string) {
  return new ApiRequest().conversation(part).assembleFullUrl(true);
}

export const conversationRoutes = {
  async get(
    conversation: Pick<Conversation, 'id'>
  ): Promise<ConversationApiResponse> {
    const url = getUrl(conversation.id);
    const response = await axios.get(url);
    return {
      ...(response.data || {}),
      conversation: parseConversation(response.data?.conversation),
    };
  },
  async create({
    conversation,
    llmType = LLMType.Text,
    llmConfig,
    emailLater = false,
  }: {
    conversation: Pick<
      Conversation,
      'formResponseId' | 'relationshipToPatient'
    >;
    llmType?: LLMType;
    llmConfig?: string;
    emailLater?: boolean;
  }): Promise<ConversationApiResponse> {
    const response = await new ApiRequest().conversations().create({
      // This does not match the expected request payload on the
      // endpoint's `ConversationSerializer`, so:
      // TODO: Update data payload to conform to `ConversationSerializer` format.
      data: {
        conversation,
        llmType,
        llmConfig: llmConfig != null ? llmConfig : undefined,
        emailLater,
      },
    });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
  async update({
    conversation,
    requestNextQuestion = false,
    llmType = LLMType.Text,
  }: {
    conversation: Readonly<Conversation>;
    requestNextQuestion?: boolean;
    llmType?: LLMType;
  }): Promise<ConversationApiResponse> {
    if (!conversation.id) {
      throw new Error(
        `Conversation id cannot be empty on update: ${conversation.id}`
      );
    }
    const response = await new ApiRequest()
      .conversation(conversation.id)
      .update({
        data: {
          conversation,
          requestNextQuestion,
          llmType,
        },
      });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
  async submit(
    conversation: Readonly<Conversation>
  ): Promise<ConversationApiResponse> {
    const response = await new ApiRequest()
      .conversation(conversation.id)
      .withAction('submit')
      .update({
        data: {
          conversation: {
            ...conversation,
            submitted: true,
          },
        },
      });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
};
