import {
  actions,
  BuiltLogic,
  defaults,
  kea,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';
import {
  emptySceneParams,
  preloadedScenes,
  redirects,
  routes,
  SceneKey,
} from '@/helpers/routes';
import { combineUrl, urlToAction } from 'kea-router';
import {
  LoadedScene,
  Params,
  SceneExport,
  SceneParams,
} from '@/lib/logics/scene.types';
import type { sceneLogicType } from './sceneLogicType';
import { analytics } from '@/helpers/analytics';
import { CHATPG_URL } from '@/helpers/constants';

export interface SceneLogicProps {
  scenes?: Record<SceneKey, () => any>;
}

export const sceneLogic = kea<sceneLogicType>([
  props({} as SceneLogicProps),
  path(['lib', 'sceneLogic']),
  actions({
    /* 1. Prepares to open the scene, as the listener may override and do something
       else (e.g. redirecting if unauthenticated), then calls (2) `loadScene`*/
    openScene: (scene: SceneKey, params: SceneParams, method: string) => ({
      scene,
      params,
      method,
    }),
    // 2. Start loading the scene's Javascript and mount any logic, then calls (3) `setScene`
    loadScene: (scene: SceneKey, params: SceneParams, method: string) => ({
      scene,
      params,
      method,
    }),
    // 3. Set the `scene` reducer
    setScene: (
      scene: SceneKey,
      params: SceneParams,
      scrollToTop: boolean = true
    ) => ({ scene, params, scrollToTop }),
    setLoadedScene: (loadedScene: LoadedScene) => ({
      loadedScene,
    }),
    reloadBrowserDueToImportError: true,
  }),
  defaults({
    scene: null as SceneKey | null,
    loadedScenes: preloadedScenes as Record<string, LoadedScene>,
    loadingScene: null as SceneKey | null,
  }),
  reducers({
    scene: {
      setScene: (_, payload) => payload.scene,
    },
    loadedScenes: {
      setScene: (state, { scene, params }) =>
        scene in state
          ? {
              ...state,
              [scene]: {
                ...state[scene],
                sceneParams: params,
                lastTouch: new Date().valueOf(),
              },
            }
          : state,
      setLoadedScene: (state, { loadedScene }) => ({
        ...state,
        [loadedScene.name]: { ...loadedScene, lastTouch: new Date().valueOf() },
      }),
    },
    loadingScene: {
      loadScene: (_, { scene }) => scene,
      setScene: () => null,
    },
    lastReloadAt: [
      null as number | null,
      { persist: true },
      {
        reloadBrowserDueToImportError: () => new Date().valueOf(),
      },
    ],
  }),
  selectors(() => ({
    activeLoadedScene: [
      (s) => [s.scene, s.loadedScenes],
      (activeScene, loadedScenes) =>
        activeScene ? loadedScenes[activeScene] : null,
    ],
    sceneParams: [
      (s) => [s.activeLoadedScene],
      (activeLoadedScene): SceneParams =>
        activeLoadedScene?.sceneParams || emptySceneParams,
    ],
    activeSceneLogic: [
      (s) => [s.activeLoadedScene, s.sceneParams],
      (activeLoadedScene, sceneParams): BuiltLogic | null =>
        activeLoadedScene?.logic
          ? activeLoadedScene.logic.build(
              activeLoadedScene.paramsToProps?.(sceneParams) || {}
            )
          : null,
    ],
    params: [
      (s) => [s.sceneParams],
      (sceneParams): Record<string, string> => sceneParams.params || {},
    ],
    searchParams: [
      (s) => [s.sceneParams],
      (sceneParams): Record<string, any> => sceneParams.searchParams || {},
    ],
    hashParams: [
      (s) => [s.sceneParams],
      (sceneParams): Record<string, any> => sceneParams.hashParams || {},
    ],
  })),
  listeners(({ actions, props, values, selectors }) => ({
    reloadBrowserDueToImportError: () => {
      window.location.reload();
    },
    setScene: async ({ scene, scrollToTop }, breakpoint, __, previousState) => {
      await breakpoint(1);
      analytics.page(scene);

      // if we clicked on a link, scroll to top
      const previousScene = selectors.scene(previousState);
      if (scrollToTop && scene !== previousScene) {
        window.scrollTo(0, 0);
      }
    },
    openScene: ({ scene, params, method }) => {
      // Add any user logic here before loading the scene
      actions.loadScene(scene, params, method);
    },
    loadScene: async ({ scene, params, method }, breakpoint) => {
      if (values.scene === scene) {
        actions.setScene(scene, params);
        return;
      }

      if (!props.scenes?.[scene]) {
        actions.setScene(SceneKey.Error404, emptySceneParams);
        return;
      }

      let loadedScene = values.loadedScenes[scene];
      const wasNotLoaded = !loadedScene;

      if (wasNotLoaded) {
        // if we can't load the scene in a second, show a spinner
        const timeout = window.setTimeout(
          () => actions.setScene(scene, params),
          500
        );
        let importedScene;
        try {
          importedScene = await props.scenes[scene]();
        } catch (error: any) {
          // Reloaded once in the last 20 seconds and now reloading again? Show network error
          if (
            values.lastReloadAt &&
            parseInt(String(values.lastReloadAt)) > new Date().valueOf() - 20000
          ) {
            console.error(
              'App assets regenerated. Showing network error page.'
            );
            actions.setScene(SceneKey.Error404, emptySceneParams);
          } else {
            console.error('App assets regenerated. Reloading this page.');
            actions.reloadBrowserDueToImportError();
            return;
          }
          console.error(error);
        } finally {
          window.clearTimeout(timeout);
        }
        breakpoint();
        const {
          default: defaultExport,
          logic,
          scene: _scene,
          ...others
        } = importedScene;

        if (_scene) {
          loadedScene = {
            name: scene,
            ...(_scene as SceneExport),
            sceneParams: params,
          };
        } else if (defaultExport) {
          console.warn(`Scene ${scene} not yet converted to use SceneExport!`);
          loadedScene = {
            name: scene,
            component: defaultExport,
            logic: logic,
            sceneParams: params,
          };
        } else {
          console.warn(`Scene ${scene} not yet converted to use SceneExport!`);
          loadedScene = {
            name: scene,
            component:
              Object.keys(others).length === 1
                ? others[Object.keys(others)[0]]
                : values.loadedScenes[SceneKey.Error404].component,
            logic: logic,
            sceneParams: params,
          };
          if (Object.keys(others).length > 1) {
            console.error(
              'There are multiple exports for this scene. Showing 404 instead.'
            );
          }
        }
        actions.setLoadedScene(loadedScene);

        if (loadedScene.logic) {
          // initialize the logic and give it 50ms to load before opening the scene
          const unmount = loadedScene.logic
            .build(loadedScene.paramsToProps?.(params) || {})
            .mount();
          try {
            await breakpoint(50);
          } catch (e) {
            // if we change the scene while waiting these 50ms, unmount
            unmount();
            throw e;
          }
        }
      }
      actions.setScene(scene, params);
    },
  })),
  urlToAction(({ actions }) => {
    const mapping: Record<
      string,
      (
        params: Params,
        searchParams: Params,
        hashParams: Params,
        payload: {
          method: string;
        }
      ) => any
    > = {};

    for (const [path, scene] of Object.entries(routes)) {
      mapping[path] = (params, searchParams, hashParams, { method }) => {
        actions.openScene(scene, { params, searchParams, hashParams }, method);
      };
    }
    for (const path of Object.keys(redirects)) {
      mapping[path] = (params, searchParams, hashParams) => {
        const redirect = redirects[path];
        window.location.replace(
          combineUrl(
            `${CHATPG_URL}${
              typeof redirect === 'function'
                ? redirect(params, searchParams, hashParams)
                : redirect
            }`,
            combineUrl(window.location.href).searchParams
          ).url
        );
      };
    }

    mapping['/*'] = (_, __, { method }) => {
      actions.loadScene(SceneKey.Error404, emptySceneParams, method);
    };

    return mapping;
  }),
]);
