import { queries, UserQuery } from '@/lib/api/user.queries';
import { ApiRequest } from '@/lib/api/api';
import {
  FormResponseType,
  GenericApiResponse,
  PatientType,
  UpdatePatientInputData,
  UpdatePersonInputData,
  UserFilterInput,
  UserType,
} from '@/helpers/types';
import { UpdatePatientEthnicitiesInput } from '@pg-web/api';

export const userRoutes: Record<UserQuery, (a?: any) => Promise<any>> = {
  async [UserQuery.Me](): Promise<{
    me: UserType;
    formResponses: FormResponseType[];
  }> {
    return await new ApiRequest().gqlGet<{
      me: UserType;
      formResponses: FormResponseType[];
    }>(UserQuery.Me, queries.me);
  },
  async [UserQuery.UserValidation](variables: {
    filter: UserFilterInput;
  }): Promise<boolean> {
    return (
      ((
        await new ApiRequest().gqlGet<{ totalCount: number }>(
          UserQuery.UserValidation,
          queries.userValidation,
          variables
        )
      )?.totalCount ?? 0) > 0
    );
  },
  async [UserQuery.SignIn](variables: {
    username: string;
    password: string;
  }): Promise<UserType> {
    return await new ApiRequest().gqlMutate<UserType>(
      UserQuery.SignIn,
      queries.signIn,
      variables
    );
  },
  async [UserQuery.SignOut](): Promise<
    Pick<UserType, 'id'> & GenericApiResponse
  > {
    return await new ApiRequest().gqlMutate<
      Pick<UserType, 'id'> & GenericApiResponse
    >(UserQuery.SignOut, queries.signOut);
  },
  async [UserQuery.RestorePassword](variables: {
    email: string;
    returnUrl: string;
  }): Promise<GenericApiResponse> {
    return await new ApiRequest().gqlMutate<GenericApiResponse>(
      UserQuery.RestorePassword,
      queries.restorePassword,
      variables
    );
  },
  async [UserQuery.ResetPassword](variables: {
    email: string;
    password: string;
    token: string;
  }): Promise<GenericApiResponse> {
    return await new ApiRequest().gqlMutate<GenericApiResponse>(
      UserQuery.ResetPassword,
      queries.resetPassword,
      variables
    );
  },
  async [UserQuery.RequestOtp](variables: {
    email: string;
  }): Promise<GenericApiResponse> {
    return await new ApiRequest().gqlMutate<GenericApiResponse>(
      UserQuery.RequestOtp,
      queries.requestOtp,
      variables
    );
  },
  async [UserQuery.VerifyOtp](variables: {
    email: string;
    otp: string;
  }): Promise<
    GenericApiResponse & {
      formResponses: FormResponseType[];
    }
  > {
    return await new ApiRequest().gqlMutate<
      GenericApiResponse & {
        formResponses: FormResponseType[];
      }
    >(UserQuery.VerifyOtp, queries.verifyOtp, variables);
  },
  async [UserQuery.UpdatePerson](variables: {
    input: UpdatePersonInputData;
  }): Promise<UserType> {
    return (
      await new ApiRequest().gqlMutate<{
        person: UserType;
      }>(UserQuery.UpdatePerson, queries.updatePerson, variables)
    ).person;
  },
  async [UserQuery.UpdatePatient](variables: {
    input: UpdatePatientInputData;
  }): Promise<PatientType> {
    return (
      await new ApiRequest().gqlMutate<{
        patient: PatientType;
      }>(UserQuery.UpdatePatient, queries.updatePatient, variables)
    ).patient;
  },
  async [UserQuery.UpdatePatientEthnicities](variables: {
    input: UpdatePatientEthnicitiesInput;
  }): Promise<PatientType> {
    return (
      await new ApiRequest().gqlMutate<{
        patient: PatientType;
      }>(
        UserQuery.UpdatePatientEthnicities,
        queries.updatePatientEthnicities,
        variables
      )
    ).patient;
  },
};
