import Axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { analytics } from '@/helpers/analytics';

export const axios = Axios.create({
  // TODO: Add baseURL so we don't need to do complicated URL building.
  timeout: 6000,
  withCredentials: true,
});

axiosRetry(axios, {
  shouldResetTimeout: true,
  retries: 10,
  retryCondition: (error) => {
    if (error.code === 'ECONNABORTED') {
      console.debug('Request timed out', { error });
      return true;
    }
    // This is the default behavior, but we also want to retry on timeouts (above).
    return axiosRetry.isNetworkOrIdempotentRequestError(error);
  },
  retryDelay: (retryCount: number, error) => {
    const delay = axiosRetry.exponentialDelay(retryCount);
    return delay + 1000;
  },
  onRetry: (
    retryCount: number,
    error: AxiosError,
    requestConfig: AxiosRequestConfig
  ) => {
    console.debug('Retrying...', { retryCount, error });
    analytics.user.requestRetry();
  },
});
