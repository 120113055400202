import { ApiRequest } from '@/lib/api/api';
import { axios } from '@/lib/api/axios';
import { Gene } from '@/helpers/types';

function getUrl(part: string) {
  return new ApiRequest().dashboard(`gene/${part}`).assembleFullUrl(true);
}

interface GeneSearchResultsType {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Gene[];
}

export const geneRoutes = {
  async search(search: string = '', limit: number = 20) {
    const url = getUrl('search');
    const params = new URLSearchParams();
    params.append('q', search);
    params.append('limit', limit.toString());
    const res = await axios.get<GeneSearchResultsType>(`${url}?${params}`);
    return res.data;
  },
};
