import cn from '@/helpers/cn';
import { Spinner } from '@heroui/react';

interface HighVolumeOverlayProps {
  visible?: boolean;
}

export function HighVolumeOverlay({ visible }: HighVolumeOverlayProps) {
  return visible ? (
    <div
      className={cn(
        'w-screen h-screen flex flex-col gap-6 justify-center items-center before:bg-default-200'
      )}
    >
      <h2 className="text-4xl md:text-5xl text-primary text-center font-bold">
        Thanks for your patience
      </h2>
      <p className="text-lg text-foreground-700 text-center max-w-lg">
        We are experiencing a high volume of traffic. Please sit tight and we
        will refresh this page for you when it's ready, or you can visit us at a
        later time.
      </p>
      <Spinner size="lg" color="default" />
    </div>
  ) : (
    <></>
  );
}
