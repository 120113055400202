export enum OrderQuery {
  DownloadReport = 'downloadReport',
  DownloadData = 'downloadData',
}

export const queries: Record<OrderQuery, string> = {
  [OrderQuery.DownloadReport]: `mutation downloadReport($input: DownloadReportInput) {
    downloadReport(input: $input) {
      url
    }
  }`,
  [OrderQuery.DownloadData]: `mutation downloadData($input: DownloadDataInput) {
    downloadData(input: $input) {
      __typename
      url
    }
  }`,
};
