import { ApiRequest } from './api';
import { getCookie } from '@/helpers/utils';
import { axios } from './axios';
import { FormResponseType } from '@/helpers/types';

function getUrl(part: string) {
  return new ApiRequest().dashboard(part).assembleFullUrl(true);
}

function getHeaders() {
  return {
    'X-CSRFToken': getCookie('csrftoken'),
  };
}

export const dashboardRoutes = {
  // These are similar to the existing `user` API routes.
  // These are REST-ish calls and the GraphQL calls should be deprecated
  user: {
    me: async () => {
      const url = getUrl('user/me');
      const res = await axios.get(url, { headers: getHeaders() });
      return res.data;
    },
    checkEmail: async (email: string): Promise<{ email?: string }> => {
      const url = getUrl('check-email');
      const res = await axios.post(
        url,
        {
          email,
        },
        { headers: getHeaders() }
      );
      return res.data?.email;
    },
    requestOtp: async (email: string): Promise<void> => {
      const url = getUrl('request-otp');
      // This returns nothing so don't return it?
      await axios.post(url, { email }, { headers: getHeaders() });
    },
    verifyOtp: async (email: string, otp: string) => {
      const url = getUrl('verify-otp');
      const res = await axios.post(
        url,
        { email, otp },
        { headers: getHeaders() }
      );
      return res.data;
    },
    isAuth: async (): Promise<{ email: string | boolean }> => {
      const url = getUrl('user/is-auth');
      const res = await axios.get(url);
      return res.data;
    },
  },

  initFormResponse: async (data: Record<string, any>) => {
    const url = getUrl(`form-response/init`);
    const res = await axios.post<FormResponseType>(url, data, {
      headers: getHeaders(),
    });
    return res.data;
  },

  createAddress: async (data: Record<string, any>) => {
    const url = getUrl(`address`);
    const res = await axios.post(url, data, { headers: getHeaders() });
    return res.data;
  },

  updateAddress: async (id: string, data: Record<string, any>) => {
    // false snakify because it will make address1 -> address_1.
    const url = getUrl(`address/${id}`);
    const res = await axios.patch(url, data, { headers: getHeaders() });
    return res.data;
  },

  updatePerson: async (id: string, data: Record<string, any>) => {
    const url = getUrl(`person/${id}`);
    const res = await axios.patch(url, data, { headers: getHeaders() });
    return res.data;
  },

  updatePatient: async (id: string, data: Record<string, any>) => {
    const url = getUrl(`patient/${id}`);
    const res = await axios.patch(url, data, { headers: getHeaders() });
    return res.data;
  },
};
